import React, { useState } from "react";
import { Form, Input, Button, Typography, message, Select, Space } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "../utils/user";
import styled from "styled-components";
import { branchOptions } from "../constants/branch";
import Nice from "./Nice";

const { Title, Text } = Typography;
const { Option } = Select;

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 40px;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
`;

const StyledLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #1890ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default function Signup() {
  const navigate = useNavigate();
  const [step, setStep] = useState(2);
  const [form] = Form.useForm();
  const [phone, setPhone] = useState(["", "", ""]);

  const handlePhoneChange = (index: number, value: string) => {
    const newPhone = [...phone];
    newPhone[index] = value;
    console.log(newPhone);
    setPhone(newPhone);
    form.setFieldsValue({ phone: newPhone.join("-") });
  };

  const onFinish = async (values: any) => {
    if(!phone[0] || !phone[1] || !phone[2]) {
      message.warning("휴대전화 번호를 입력해주세요.");
      return;
    }
    if (values.password !== values.confirmPassword) {
      message.error("비밀번호가 일치하지 않습니다.");
      return;
    }

    const userData = {
      id: values.email,
      username: values.username,
      nickname: values.nickname,
      password: values.password,
      branch: values.branch,
      phone: phone.join("-"),
    };

    console.log(userData);
    const result = await createUser(userData);
    if (result.success) {
      message.success("회원가입 요청이 완료되었습니다!");
      navigate("/login");
    } else {
      message.error(result?.error || "회원가입 중 오류가 발생했습니다.");
    }
  };

  return (
    <PageContainer>
      <Form
        name="signup"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{
          width: "100%",
          maxWidth: 400,
          padding: "0 20px",
          boxSizing: "border-box",
        }}
      >
        <Title
          level={2}
          style={{ textAlign: "center", marginBottom: "1.5rem" }}
        >
          회원가입
        </Title>
        {step === 1 && <Nice />}
        {step === 2 && (
          <>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "이메일을 입력해주세요!" },
                {
                  type: "email",
                  message: "유효한 이메일 주소를 입력해주세요!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined style={{ marginRight: 5 }} />}
                placeholder="이메일"
                size="middle"
              />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                { required: true, message: "사용자 이름을 입력해주세요!" },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ marginRight: 5 }} />}
                placeholder="사용자 이름"
                size="middle"
              />
            </Form.Item>
            <Form.Item
              name="nickname"
              rules={[{ required: true, message: "닉네임을 입력해주세요!" }]}
            >
              <Input
                prefix={<UserOutlined style={{ marginRight: 5 }} />}
                placeholder="닉네임"
                size="middle"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "비밀번호를 입력해주세요!" }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ marginRight: 5 }} />}
                placeholder="비밀번호"
                size="middle"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true, message: "비밀번호 확인을 입력해주세요!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("비밀번호가 일치하지 않습니다!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ marginRight: 5 }} />}
                placeholder="비밀번호 확인"
                size="middle"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              // rules={[{ required: true, message: "전화번호를 입력해주세요!" }]}
            >
              <Input style={{ display: "none" }} value={`${phone[0]}-${phone[1]}-${phone[2]}`}/>
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  prefix={<PhoneOutlined style={{ marginRight: 5 }} />}
                  placeholder="010"
                  maxLength={3}
                  value={phone[0]}
                  onChange={(e) => handlePhoneChange(0, e.target.value)}
                  style={{ width: "30%" }}
                />
                <Input
                  placeholder="1234"
                  maxLength={4}
                  value={phone[1]}
                  onChange={(e) => handlePhoneChange(1, e.target.value)}
                  style={{ width: "35%" }}
                />
                <Input
                  placeholder="5678"
                  maxLength={4}
                  value={phone[2]}
                  onChange={(e) => handlePhoneChange(2, e.target.value)}
                  style={{ width: "35%" }}
                />
              </Space.Compact>
            </Form.Item>

            <Form.Item
              name="branch"
              rules={[{ required: true, message: "기본 지점을 선택해주세요!" }]}
            >
              <Select
                placeholder="기본 지점 선택"
                size="middle"
                suffixIcon={<EnvironmentOutlined style={{ marginRight: 5 }} />}
              >
                {branchOptions.map((option) => (
                  <Option key={option.value} value={option.label}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                style={{ width: "100%" }}
              >
                회원가입
              </Button>
            </Form.Item>
            <div style={{ wordBreak: "keep-all", marginBottom: 40, textAlign: "center" }}>
              회원가입 후 관리자의 현장 승인이 필요합니다. 카운터로
              문의해주세요.
            </div>
          </>
        )}

        <StyledLink to="/login">이미 계정이 있으신가요? 로그인하기</StyledLink>

        <Text
          type="secondary"
          style={{ display: "block", textAlign: "center", marginTop: "1rem" }}
        >
          © 2024 Double Up. All rights reserved.
        </Text>
      </Form>
    </PageContainer>
  );
}