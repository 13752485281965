import { Button, Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Text } = Typography;

const WarningText = styled(Text)`
  color: #ff4d4f;
  font-weight: bold;
`;

const Container = styled(Space)`
  align-items: center;
  flex-direction: column;
`;

export default function Nice() {
  return (
    <Container>
      <Button type="primary">본인인증하기</Button>
      <WarningText>* 성인(만 19세 이상)만 이용 가능합니다.</WarningText>
    </Container>
  )
}