import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  GetCommand,
  PutCommand,
  UpdateCommand,
  QueryCommand,
  ScanCommand,
  DeleteCommand,
} from "@aws-sdk/lib-dynamodb";
import { createLog, deleteUserLogs } from "./log";
import { SHA256 } from "crypto-js";
import { branchOptions } from "../constants/branch";
import { invokeLambda } from "./lambda";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const docClient = DynamoDBDocumentClient.from(client);

const getKRCurrent = () => {
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const korNow = new Date(utc + koreaTimeDiff);

  const formattedDate =
    korNow.getFullYear() +
    "-" +
    String(korNow.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(korNow.getDate()).padStart(2, "0") +
    " " +
    String(korNow.getHours()).padStart(2, "0") +
    ":" +
    String(korNow.getMinutes()).padStart(2, "0") +
    ":" +
    String(korNow.getSeconds()).padStart(2, "0") +
    "." +
    String(korNow.getMilliseconds()).padStart(3, "0") +
    " KST";

  return formattedDate;
};

export const createUser = async (userData: any) => {
  // 먼저 사용자가 존재하는지 확인
  const getCommand = new GetCommand({
    TableName: "user",
    Key: {
      id: userData.id,
    },
  });

  try {
    const { Item } = await docClient.send(getCommand);
    if (Item) {
      return { success: false, error: "이미 존재하는 이메일입니다." };
    }
  } catch (error) {
    console.error("사용자 확인 중 오류 발생:", error);
    return { success: false, error: "사용자 확인 중 오류가 발생했습니다." };
  }

  const points: any = {};

  for (const i in branchOptions) {
    points[branchOptions[i].label] = 0;
  }
  // 사용자가 존재하지 않으면 새로 생성
  const putCommand = new PutCommand({
    TableName: "user",
    Item: {
      id: userData.id,
      username: userData.username,
      nickname: userData.nickname,
      branch: userData.branch,
      phone: userData.phone || "",
      password: SHA256(userData.password).toString(),
      isApproved: false,
      coin: 0,
      point: points,
      score: 0,
      dataAccessibility: false,
      gsiPartitionKey: "ALL",
      timestamp: getKRCurrent(),
    },
  });

  try {
    console.log(
      "Creating user with command:",
      JSON.stringify(putCommand, null, 2)
    );
    const result = await docClient.send(putCommand);
    console.log("User creation result:", JSON.stringify(result, null, 2));
    return { success: true };
  } catch (error) {
    console.error("사용자 생성 중 오류 발생:", error);
    return { success: false, error: "사용자 생성 중 오류가 발생했습니다." };
  }
};

export const loginUser = async (id: string, password: string) => {
  const command = new GetCommand({
    TableName: "user",
    Key: {
      id: id,
    },
  });

  try {
    const { Item } = await docClient.send(command);
    if (!Item) {
      return {
        success: false,
        error: "이메일 또는 비밀번호가 잘못되었습니다.",
      };
    } else if (
      Item.password === SHA256(password).toString() &&
      Item.isApproved
    ) {
      // 실제 앱에서는 비밀번호 해시를 비교해야 합니다
      return { success: true, user: Item };
    } else {
      if (Item.password !== SHA256(password).toString()) {
        return {
          success: false,
          error: "이메일 또는 비밀번호가 잘못되었습니다.",
        };
      } else if (!Item.isApproved) {
        return {
          success: false,
          error: "관리자의 승인이 필요합니다.",
        };
      }
      return {
        success: false,
        error: "이메일 또는 비밀번호가 잘못되었습니다.",
      };
    }
  } catch (error) {
    console.error("로그인 중 오류 발생:", error);
    return { success: false, error: "로그인 중 오류가 발생했습니다." };
  }
};

export const updateUser = async (
  userData: any,
  from: any,
  isAdmin?: boolean,
  reason?: string
) => {
  // 현재 사용자 데이터 조회
  const getCommand = new GetCommand({
    TableName: "user",
    Key: { id: userData.id },
  });

  try {
    const { Item: currentUser } = await docClient.send(getCommand);

    console.log(userData.prevBranch);
    const updateCommand = new UpdateCommand({
      TableName: "user",
      Key: {
        id: userData.id,
      },
      UpdateExpression:
        "set username = :username, coin = :coin, point = :point, score = :score, dataAccessibility = :dataAccessibility, branch = :branch, isAdmin = :isAdmin, isApproved = :isApproved, prevBranch = :prevBranch, phone = :phone, isPointAdmin = :isPointAdmin",
      ExpressionAttributeValues: {
        ":username": userData.username,
        ":coin": userData.coin,
        ":point": userData.point,
        ":score": userData.score || {},
        ":dataAccessibility": userData.dataAccessibility,
        ":branch": userData.branch,
        ":isApproved": userData.isApproved ? true : false,
        ":isAdmin": userData?.isAdmin ? true : false,
        ":prevBranch": {
          branch: userData?.prevbranch?.branch || "",
          score: userData?.prevbranch?.score || {},
        },
        ":phone": userData?.phone || "",
        ":isPointAdmin": userData?.isPointAdmin ? true : false,
      },
      ReturnValues: "ALL_NEW",
    });

    console.log(updateCommand);
    const { Attributes: updatedUser } = await docClient.send(updateCommand);

    if (!currentUser || !updatedUser)
      return {
        success: false,
        error: "사용자 업데이트 중 오류가 발생했습니다.",
      };

    if (!isAdmin) return { success: true, user: updatedUser };

    // 변경된 값에 대해 로그 생성
    if (currentUser.coin !== updatedUser.coin) {
      await createLog(
        userData.id,
        "coin",
        currentUser.coin,
        updatedUser.coin,
        from.branch,
        from.username,
        currentUser.username,
        currentUser.nickname,
        userData.branch,
        reason
      );
    }

    // 포인트 변경 로그 생성
    for (const branch in updatedUser.point) {
      if (currentUser.point[branch] !== updatedUser.point[branch]) {
        await createLog(
          userData.id,
          `point_${branch}`,
          currentUser.point[branch] || 0,
          updatedUser.point[branch],
          from.branch,
          from.username,
          currentUser.username,
          currentUser.nickname,
          userData.branch
        );
      }
    }

    if (updatedUser.score) {
      for (const branch in updatedUser.score) {
        if (currentUser?.score[branch] !== updatedUser?.score[branch]) {
          await createLog(
            userData.id,
            `score_${branch}`,
            currentUser?.score[branch] || 0,
            updatedUser?.score[branch],
            from.branch,
            from.username,
            currentUser.username,
            currentUser.nickname,
            userData.branch
          );
        }
      }
    }

    // 기본 지점 변경 로그 생성
    if (currentUser.branch !== updatedUser.branch) {
      await createLog(
        userData.id,
        "branch",
        currentUser.branch,
        updatedUser.branch,
        from.branch,
        from.username,
        currentUser.username,
        currentUser.nickname,
        userData.branch
      );
    }

    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("사용자 업데이트 중 오류 발생:", error);
    return { success: false, error: "사용자 업데이트 중 오류가 발생했습니다." };
  }
};

export const readUsers = async (limit: number = 10, lastEvaluatedKey?: any) => {
  const queryCommandInput: any = {
    TableName: "user",
    IndexName: "gsiPartitionKey-coin-index",
    KeyConditionExpression: "gsiPartitionKey = :partitionKey",
    ExpressionAttributeValues: {
      ":partitionKey": "ALL" as any, // 모든 사용자에 대해 같은 파티션 키를 사용한다고 가정
    },
    ScanIndexForward: false, // false로 설정하여 내림차순으로 정렬
    Limit: limit,
  };

  // lastEvaluatedKey가 null이 아니고 유효한 객체인 경우에만 ExclusiveStartKey 설정
  if (
    lastEvaluatedKey &&
    typeof lastEvaluatedKey === "object" &&
    Object.keys(lastEvaluatedKey).length > 0
  ) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const queryCommand = new QueryCommand(queryCommandInput);

  try {
    const { Items, LastEvaluatedKey } = await docClient.send(queryCommand);

    return {
      success: true,
      users: Items,
      lastEvaluatedKey: LastEvaluatedKey,
    };
  } catch (error) {
    console.error("사용자 조회 중 오류 발생:", error);
    return { success: false, error: "사용자 조회 중 오류가 발생했습니다." };
  }
};

export const readBranch = async (
  limit: number = 10,
  lastEvaluatedKey?: any
) => {
  const queryCommandInput: any = {
    TableName: "user",
    IndexName: "gsiPartitionKey-score-index",
    KeyConditionExpression: "gsiPartitionKey = :partitionKey",
    ExpressionAttributeValues: {
      ":partitionKey": "ALL" as any, // 모든 사용자에 대해 같은 파티션 키를 사용한다고 가정
    },
    ScanIndexForward: false, // false로 설정하여 내림차순으로 정렬
    Limit: limit,
  };

  // lastEvaluatedKey가 null이 아니고 유효한 객체인 경우에만 ExclusiveStartKey 설정
  if (
    lastEvaluatedKey &&
    typeof lastEvaluatedKey === "object" &&
    Object.keys(lastEvaluatedKey).length > 0
  ) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const queryCommand = new QueryCommand(queryCommandInput);

  try {
    const { Items, LastEvaluatedKey } = await docClient.send(queryCommand);
    console.log(Items);
    return {
      success: true,
      users: Items,
      lastEvaluatedKey: LastEvaluatedKey,
    };
  } catch (error) {
    console.error("사용자 조회 중 오류 발생:", error);
    return { success: false, error: "사용자 조회 중 오류가 발생했습니다." };
  }
};

export const getUserById = async (id: string): Promise<any | null> => {
  try {
    const command = new GetCommand({
      TableName: "user",
      Key: { id },
    });

    const { Item } = await docClient.send(command);
    console.log(Item);
    return Item as any | null;
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    throw error;
  }
};

export const updateProfileImage = async (userId: string, imageUrl: string) => {
  const updateCommand = new UpdateCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    UpdateExpression: "set profileImage = :profileImage",
    ExpressionAttributeValues: {
      ":profileImage": imageUrl,
    },
    ReturnValues: "ALL_NEW",
  });

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("프로필 이미지 업데이트 중 오류 발생:", error);
    return {
      success: false,
      error: "프로필 이미지 업데이트 중 오류가 발생했습니다.",
    };
  }
};

export const updateNickname = async (userId: string, nickname: string) => {
  const updateCommand = new UpdateCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    UpdateExpression: "set nickname = :nickname, #mod = :isModified",
    ExpressionAttributeValues: {
      ":nickname": nickname,
      ":isModified": true,
    },
    ExpressionAttributeNames: {
      "#mod": "isModified",
    },
    ReturnValues: "ALL_NEW",
  });

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("닉네임 업데이트 중 오류 발생:", error);
    return { success: false, error: "닉네임 업데이트 중 오류가 발생했습니다." };
  }
};

export const updatePassword = async (userId: string, password: string) => {
  const updateCommand = new UpdateCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    UpdateExpression: "set password = :password",
    ExpressionAttributeValues: {
      ":password": SHA256(password).toString(),
    },

    ReturnValues: "ALL_NEW",
  });

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("비밀번호 업데이트 중 오류 발생:", error);
    return {
      success: false,
      error: "비밀번호 업데이트 중 오류가 발생했습니다.",
    };
  }
};

export const updateApprovement = async (
  userId: string,
  isApproved: boolean
) => {
  const updateCommand = new UpdateCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    UpdateExpression: "set isApproved = :isApproved",
    ExpressionAttributeValues: {
      ":isApproved": isApproved,
      ":isModified": true,
    },
    ExpressionAttributeNames: {
      "#mod": "isModified",
    },
    ReturnValues: "ALL_NEW",
  });

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("닉네임 업데이트 중 오류 발생:", error);
    return { success: false, error: "닉네임 업데이트 중 오류가 발생했습니다." };
  }
};

export const updateBranch = async (
  userId: string,
  branch: string,
  isAdmin = false
) => {
  let updateCommand = null;

  if (!isAdmin) {
    updateCommand = new UpdateCommand({
      TableName: "user",
      Key: {
        id: userId,
      },
      UpdateExpression: "set branchRequest = :branchRequest",
      ExpressionAttributeValues: {
        ":branchRequest": branch,
      },
      ReturnValues: "ALL_NEW",
    });
  } else {
    updateCommand = new UpdateCommand({
      TableName: "user",
      Key: {
        id: userId,
      },
      UpdateExpression: "set branch = :branch",
      ExpressionAttributeValues: {
        ":branch": branch,
      },
      ReturnValues: "ALL_NEW",
    });
  }

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("기본 지점 업데이트 중 오류 발생:", error);
    return {
      success: false,
      error: "기본 지점 업데이트 중 오류가 발생했습니다.",
    };
  }
};

export const readBranchRequestedUsers = async (
  limit: number = 10,
  lastEvaluatedKey?: any
) => {
  const queryCommandInput: any = {
    TableName: "user",

    IndexName: "gsiPartitionKey-branchRequest-index",
    KeyConditionExpression: "gsiPartitionKey = :partitionKey",
    // FilterExpression: "attribute_exists(branchRequest)",
    ExpressionAttributeValues: {
      ":partitionKey": "ALL",
    },
    ScanIndexForward: false,
    Limit: limit,
  };

  if (
    lastEvaluatedKey &&
    typeof lastEvaluatedKey === "object" &&
    Object.keys(lastEvaluatedKey).length > 0
  ) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  console.log(queryCommandInput);
  const queryCommand = new QueryCommand(queryCommandInput);

  try {
    const { Items, LastEvaluatedKey } = await docClient.send(queryCommand);
    console.log("Branch requested users:", Items);
    return {
      success: true,
      users: Items,
      lastEvaluatedKey: LastEvaluatedKey,
    };
  } catch (error) {
    console.error("지점 변경 요청 사용자 조회 중 오류 발생:", error);
    return {
      success: false,
      error: "지점 변경 요청 사용자 조회 중 오류가 발생했습니다.",
    };
  }
};

export const readSignupRequestedUsers = async (
  limit: number = 10,
  lastEvaluatedKey?: any
) => {
  const queryCommandInput: any = {
    TableName: "user",
    IndexName: "gsiPartitionKey-isApproved-index",
    KeyConditionExpression:
      "gsiPartitionKey = :partitionKey AND isApproved = :isApproved",
    ExpressionAttributeValues: {
      ":partitionKey": "ALL",
      ":isApproved": new Uint8Array([0]), // false를 이진 형식으로 표현
    },
    ScanIndexForward: false,
    Limit: limit,
  };

  if (
    lastEvaluatedKey &&
    typeof lastEvaluatedKey === "object" &&
    Object.keys(lastEvaluatedKey).length > 0
  ) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const queryCommand = new QueryCommand(queryCommandInput);

  try {
    const { Items, LastEvaluatedKey } = await docClient.send(queryCommand);
    console.log("Signup requested users:", Items);
    return {
      success: true,
      users: Items,
      lastEvaluatedKey: LastEvaluatedKey,
    };
  } catch (error) {
    console.error("가입 승인 대기 사용자 조회 중 오류 발생:", error);
    return {
      success: false,
      error: "가입 승인 대기 사용자 조회 중 오류가 발생했습니다.",
    };
  }
};

export const approveBranchRequest = async (
  userId: string,
  branchRequest: string
) => {
  const updateCommand = new UpdateCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    UpdateExpression: "SET #b = :branch REMOVE branchRequest",
    ExpressionAttributeValues: {
      ":branch": branchRequest,
    },
    ExpressionAttributeNames: {
      "#b": "branch",
    },
    ReturnValues: "ALL_NEW",
  });

  console.log("Update command:", JSON.stringify(updateCommand, null, 2));

  try {
    const { Attributes: updatedUser } = await docClient.send(updateCommand);
    console.log("Updated user:", updatedUser);
    return { success: true, user: updatedUser };
  } catch (error) {
    console.error("지점 승인 중 오류 발생:", error);
    if (error instanceof Error) {
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
    }
    return { success: false, error: "지점 승인 중 오류가 발생했습니다." };
  }
};

export const searchUsers = async (searchTerm: string) => {
  console.log(searchTerm);
  const scanCommand = new ScanCommand({
    TableName: "user",
    FilterExpression:
      "contains(#username, :searchTerm) OR contains(#phone, :searchTerm) OR contains(#nickname, :searchTerm)",
    ExpressionAttributeNames: {
      "#username": "username",
      "#phone": "phone",
      "#nickname": "nickname",
    },
    ExpressionAttributeValues: {
      ":searchTerm": searchTerm,
    },
  });

  try {
    const { Items } = await docClient.send(scanCommand);
    console.log(Items);
    return { success: true, users: Items };
  } catch (error) {
    console.error("사용자 검색 중 오류 발생:", error);
    return { success: false, error: "사용자 검색 중 오류가 발생했습니다." };
  }
};

export const scanAllUsers = async (
  limit: number = 50,
  lastEvaluatedKey?: any
) => {
  const scanCommandInput: any = {
    TableName: "user",
    // Limit: limit,
  };

  if (lastEvaluatedKey) {
    scanCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const scanCommand = new ScanCommand(scanCommandInput);

  try {
    const { Items, LastEvaluatedKey } = await docClient.send(scanCommand);

    // Sort items by timestamp, handling cases where timestamp might not exist
    const sortedItems = Items?.sort((a, b) => {
      if (!a.timestamp && !b.timestamp) return 0;
      if (!a.timestamp) return 1;
      if (!b.timestamp) return -1;
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    console.log("Scanned users:", sortedItems);
    return {
      success: true,
      users: sortedItems,
      lastEvaluatedKey: LastEvaluatedKey,
    };
  } catch (error) {
    console.error("모든 사용자 스캔 중 오류 발생:", error);
    return {
      success: false,
      error: "모든 사용자를 스캔하는 중 오류가 발생했습니다.",
    };
  }
};

export const deleteUser = async (userId: string) => {
  const initialBranchData = Object.fromEntries(
    branchOptions.map((branch) => [branch.label, 0])
  );

  const initialUserData = {
    id: userId,
    coin: 0,
    point: { ...initialBranchData },
    score: { ...initialBranchData },
    gsiPartitionKey: "ALL",
    username: "차왕준",
  };

  const putCommand = new PutCommand({
    TableName: "user",
    Item: initialUserData,
  });

  const deleteCommand = new DeleteCommand({
    TableName: "user",
    Key: {
      id: userId,
    },
    ReturnValues: "ALL_OLD",
  });

  try {
    // 사용자 데이터 초기화
    console.log("사용자 데이터 초기화 시작");
    await docClient.send(putCommand);
    console.log("사용자 데이터 초기화 완료");

    // 사용자 로그 삭제
    console.log("사용자 로그 삭제 시작");
    await deleteUserLogs(userId);
    console.log("사용자 로그 삭제 완료");

    // 사용자 삭제
    console.log("사용자 삭제 시작");
    const { Attributes: deletedUser } = await docClient.send(deleteCommand);
    console.log("사용자 삭제 완료");

    if (deletedUser) {
      console.log("삭제된 사용자:", deletedUser);
      return { success: true, user: deletedUser };
    } else {
      return { success: false, error: "사용자를 찾을 수 없습니다." };
    }
  } catch (error) {
    console.error("사용자 삭제 중 오류 발생:", error);
    return { success: false, error: "사용자 삭제 중 오류가 발생했습니다." };
  }
};

export const queryUsers = async (
  pageSize: number = 10,
  lastEvaluatedKey?: any
) => {
  console.log("Query Input:", { pageSize, lastEvaluatedKey });

  const queryCommandInput: any = {
    TableName: "user",
    IndexName: "gsiPartitionKey-timestamp-index",
    KeyConditionExpression: "gsiPartitionKey = :partitionKey",
    ExpressionAttributeValues: {
      ":partitionKey": "ALL",
    },
    ScanIndexForward: false, // 내림차순 정렬 (최신순)
    Limit: pageSize,
  };

  if (lastEvaluatedKey) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const queryCommand = new QueryCommand(queryCommandInput);

  try {
    const response = await docClient.send(queryCommand);
    // console.log("Query Response:", JSON.stringify(response, null, 2));

    const { Items, LastEvaluatedKey, Count, ScannedCount } = response;
    console.log(response);
    if (!Items || Items.length === 0) {
      console.warn("No items returned from the query.");
    }

    return {
      success: true,
      users: Items || [],
      lastEvaluatedKey: LastEvaluatedKey,
      total: Count || 0,
    };
  } catch (error) {
    console.error("사용자 쿼리 중 오류 발생:", error);
    return {
      success: false,
      error: "사용자를 쿼리하는 중 오류가 발생했습니다.",
      details: error instanceof Error ? error.message : String(error),
    };
  }
};

export const resetScoresByBranch = async (branch: string) => {
  console.log(`Resetting scores for branch: ${branch}`);

  const scanCommand = new ScanCommand({
    TableName: "user",
  });

  try {
    const { Items } = await docClient.send(scanCommand);

    if (!Items || Items.length === 0) {
      console.log(`No users found`);
      return { success: true, message: `No users found` };
    }

    console.log(`Found ${Items.length} users`);

    for (const user of Items) {
      let updateParams: any;

      if (branch === "ALL") {
        // Reset scores for all branches
        updateParams = {
          TableName: "user",
          Key: { id: user.id },
          UpdateExpression: "SET #score = :emptyMap",
          ExpressionAttributeNames: {
            "#score": "score",
          },
          ExpressionAttributeValues: {
            ":emptyMap": {},
          },
          ReturnValues: "ALL_NEW",
        };
      } else {
        // Reset or add score for specific branch
        updateParams = {
          TableName: "user",
          Key: { id: user.id },
          UpdateExpression: "SET #score.#branch = :zero",
          ExpressionAttributeNames: {
            "#score": "score",
            "#branch": branch,
          },
          ExpressionAttributeValues: {
            ":zero": 0,
          },
          ReturnValues: "ALL_NEW",
        };
      }

      try {
        const updateCommand = new UpdateCommand(updateParams);
        const { Attributes: updatedUser } = await docClient.send(updateCommand);

        // Log the score reset (commented out for now)
        // if (updatedUser) {
        //   if (branch === "ALL") {
        //     for (const [branchKey, score] of Object.entries(user.score || {})) {
        //       await createLog(
        //         user.id,
        //         `score_${branchKey}`,
        //         score,
        //         0,
        //         branchKey,
        //         "SYSTEM",
        //         user.username,
        //         user.nickname,
        //         branchKey,
        //         "월간 랭킹 점수 초기화 (전체)"
        //       );
        //     }
        //   } else {
        //     await createLog(
        //       user.id,
        //       `score_${branch}`,
        //       user.score?.[branch] || 0,
        //       0,
        //       branch,
        //       "SYSTEM",
        //       user.username,
        //       user.nickname,
        //       branch,
        //       "월간 랭킹 점수 초기화"
        //     );
        //   }
        // }
      } catch (updateError) {
        console.error(`Error updating user ${user.id}:`, updateError);
      }
    }

    console.log(
      `Successfully reset scores for ${Items.length} users ${branch === "ALL" ? "across all branches" : `in branch: ${branch}`}`
    );
    return {
      success: true,
      message: `Successfully reset scores for ${Items.length} users ${branch === "ALL" ? "across all branches" : `in branch: ${branch}`}`,
    };
  } catch (error) {
    console.error(`Error resetting scores ${branch === "ALL" ? "for all branches" : `for branch ${branch}`}:`, error);
    return {
      success: false,
      error: `Error occurred while resetting scores ${branch === "ALL" ? "for all branches" : `for branch ${branch}`}`,
    };
  }
};
