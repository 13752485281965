import React, { useEffect, useState } from "react";
import { List, Typography, Space, Spin, Modal, Carousel } from "antd";
import {
  PhoneOutlined,
  MessageOutlined,
  EnvironmentOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { fetchLocations } from "../utils/location";
import styled from "styled-components";
import { Map, MapMarker } from "react-kakao-maps-sdk";

const { Title, Text } = Typography;

const SquareImage = styled.div<{ imageUrl?: string }>`
  width: 150px;
  height: 150px;
  background-image: ${(props) =>
    props.imageUrl ? `url(${props.imageUrl})` : "none"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const LocationName = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ImageGallery = styled.div`
  .slick-slide {
    div {
      display: flex;
    }
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
`;

const StyledCarousel = styled(Carousel)`
  .slick-dots {
    bottom: 10px;
  }
  .slick-dots li {
    width: 15px;
    height: 15px;
    margin: 0 8px;
  }
  .slick-dots li button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.682);
    opacity: 1;
  }
  .slick-dots li.slick-active {
    width: 15px;
  }
  .slick-dots li.slick-active button {
    background: #1668dc;
  }
`;

const StyledLink = styled.a`
  color: #1668dc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled.span`
  color: #777;
  margin-right: 8px;
`;

interface Location {
  id: string;
  name: string;
  address: string;
  phone: string;
  kakaoLink: string;
  mainImage?: string;
  images?: string[];
  lat?: number;
  lng?: number;
  lastModifiedAt: string;
}

export default function Location() {
  const [locations, setLocations] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    setLoading(true);
    try {
      const result = await fetchLocations();
      if (result.success) {
        const sortedLocations = (result.locations || []).sort(
          (a: any, b: any) => a.createdAt - b.createdAt
        );
        console.log(sortedLocations);
        setLocations(sortedLocations);
      } else {
        console.error("Failed to fetch locations:", result.error);
      }
    } catch (error) {
      console.error("Error loading locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationClick = (location: Location) => {
    setSelectedLocation(location);
    setIsModalVisible(true);
  };

  const carouselSettings = {
    dots: true,

    prevArrow: <LeftOutlined />,
    nextArrow: <RightOutlined />,
  };

  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }
  const handlePhoneClick = (e: React.MouseEvent, phone: string) => {
    e.stopPropagation(); // 이벤트 버블링 방지
  };

  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        padding: "24px",
        width: "100%",
        maxWidth: 1200,
        boxSizing: "border-box",
        margin: "0 auto",
      }}
    >
      <Title level={2}>지점</Title>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={locations}
        renderItem={(item: Location) => (
          <List.Item
            key={item.id}
            onClick={() => handleLocationClick(item)}
            style={{
              cursor: "pointer",
              padding: "20px 0",
              borderBottom: "1px solid #3c3c3c",
            }}
          >
            <div style={{ display: "flex" }}>
              <SquareImage
                imageUrl={
                  item.mainImage
                    ? `${process.env.REACT_APP_S3_STORAGE}/${item.mainImage}`
                    : undefined
                }
              />
              <ContentWrapper>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <LocationName>{item.name}</LocationName>
                  <Text type="secondary">
                    <IconWrapper>
                      <EnvironmentOutlined />
                    </IconWrapper>
                    {item.address}
                  </Text>
                </div>
                <LocationInfo>
                  <Text>
                    <IconWrapper>
                      <PhoneOutlined />
                    </IconWrapper>
                    <StyledLink
                      href={`tel:${item.phone}`}
                      onClick={handlePhoneClick as any}
                    >
                      {item.phone}
                    </StyledLink>
                  </Text>
                  <StyledLink
                    href={item.kakaoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconWrapper>
                      <MessageOutlined />
                    </IconWrapper>
                    카카오톡 오픈채팅
                  </StyledLink>
                </LocationInfo>
              </ContentWrapper>
            </div>
          </List.Item>
        )}
      />
      <Modal
        title={selectedLocation?.name}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
        centered
      >
        {selectedLocation && (
          <ModalContent>
            <ImageGallery>
              <StyledCarousel {...carouselSettings}>
                {selectedLocation.images &&
                selectedLocation.images.length > 0 ? (
                  selectedLocation.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={`${process.env.REACT_APP_S3_STORAGE}/${image}`}
                        alt={`${selectedLocation.name} - ${index + 1}`}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          aspectRatio: 4 / 3,
                          borderRadius: 8,
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      height: "400px",
                      background: "#f0f0f0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Image
                  </div>
                )}
              </StyledCarousel>
            </ImageGallery>
            <div>
              <p>
                <IconWrapper style={{ color: "#fff" }}>
                  <EnvironmentOutlined />
                </IconWrapper>
                {selectedLocation.address}
              </p>
              <p>
                <IconWrapper>
                  <PhoneOutlined />
                </IconWrapper>
                <StyledLink href={`tel:${selectedLocation.phone}`}>
                  {selectedLocation.phone}
                </StyledLink>
              </p>
              <p>
                <IconWrapper>
                  <MessageOutlined />
                </IconWrapper>
                <StyledLink
                  href={selectedLocation.kakaoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  카카오톡 오픈채팅
                </StyledLink>
              </p>
            </div>
            <MapContainer>
              <Map
                center={{
                  lat: selectedLocation.lat || 37.566826,
                  lng: selectedLocation.lng || 126.9786567,
                }}
                style={{ width: "100%", height: "100%", borderRadius: 8 }}
                level={3}
              >
                <MapMarker
                  position={{
                    lat: selectedLocation.lat || 37.566826,
                    lng: selectedLocation.lng || 126.9786567,
                  }}
                />
              </Map>
            </MapContainer>
          </ModalContent>
        )}
      </Modal>
    </Space>
  );
}
