import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Space, Spin, Button } from "antd";
import { getNewsById } from "../utils/news"; // 이 함수는 추가로 구현해야 합니다
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { AiOutlineMenu } from "react-icons/ai";

const { Title, Text } = Typography;

const Content = styled.div`
  color: #fff;
  img {
    width: 100%;
    border-radius: 8px;
  }
`;
interface News {
  id: string;
  title: string;
  content: string;
  lastModifiedAt: string;
  mainImage?: string;
}

export default function NewsDetail() {
  const [news, setNews] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      fetchNewsDetail(id);
    }
  }, [id]);

  const fetchNewsDetail = async (newsId: string) => {
    setLoading(true);
    const result = await getNewsById(newsId);
    if (result.success) {
      if (!result.news) return;
      setNews(result.news);
    } else {
      console.error("Failed to fetch news detail:", result.error);
      // You might want to show an error message to the user here
    }
    setLoading(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }

  if (!news) {
    return <Title level={3}>뉴스를 찾을 수 없습니다.</Title>;
  }

  console.log(news);
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        padding: "24px",
        width: "100%",
        maxWidth: 1200,
        boxSizing: "border-box",
        margin: "0 auto",
        // gap: 5,
      }}
    >

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          marginTop: 20,
          alignItems: "center",
        }}
      >
        <Title
          level={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 0,
          }}
        >
          {news.title}
        </Title>

        <Button
          style={{ padding: 3, marginRight: 20 }}
          type="text"
          onClick={() => navigate("/news")}
        >
          <AiOutlineMenu size={30} />
        </Button>
      </div>
      <Text type="secondary" >등록일시 : {formatDate(news.lastModifiedAt)}</Text>
      <img
        src={`${news.mainImage}`}
        style={{ borderRadius: 8, width: "100%", marginTop: 20 }}
      />
      {news.images && news.images.map((url: string) => {
        return       <img
        src={`${url}`}
        style={{ borderRadius: 8, width: "100%", marginTop: 20 }}
      />
      })}
      <Content dangerouslySetInnerHTML={{ __html: news.content }} />
    </Space>
  );
}
