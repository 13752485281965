import React, { useState, useEffect } from "react";
import { Upload, message, Button, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { RcFile } from "antd/es/upload";
import { uploadToS3 } from "../../utils/s3";

const BannerImageAdmin = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState(new Date().toISOString());

  useEffect(() => {
    fetchBannerImage();
  }, []);

  const fetchBannerImage = async () => {
    try {
      // S3에서 이미지 URL을 가져오는 로직 구현 필요
      // 예: const url = await getS3ImageUrl('banner-image');
      // setImageUrl(url);
    } catch (error) {
      console.error("Error fetching banner image:", error);
    }
  };

  const handleUpload = async (file: RcFile) => {
    setLoading(true);
    try {
      await uploadToS3(file, "banner-image");
      // S3 버킷의 URL 구조에 맞게 이미지 URL 생성

      message.success("일정표 사진이 성공적으로 업로드되었습니다.");
      setTemp(new Date().toISOString());
      // 여기에 업로드된 이미지 파일명을 서버에 저장하는 로직 추가 필요
      // 예: await updateBannerImageFileName(uploadedFileName);
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("일정표 사진 업로드에 실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Upload
        accept="image/*"
        beforeUpload={(file) => {
          handleUpload(file);
          return false; // 자동 업로드 방지
        }}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />} loading={loading}>
          일정표 사진 업로드
        </Button>
      </Upload>
      {(
        <div style={{ marginTop: "20px" }}>
          <Image src={`${process.env.REACT_APP_S3_STORAGE}/banner-image?t=${temp}`} alt="Banner" style={{ maxWidth: "100%" }} />
        </div>
      )}
    </div>
  );
};

export default BannerImageAdmin;
