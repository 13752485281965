import { S3Client, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { RcFile } from "antd/es/upload";
import uuid from "react-uuid";

// S3 클라이언트 초기화
const s3Client = new S3Client({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "",
  },
});

// 이미지 리사이징 함수
const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob as Blob);
        }, 'image/jpeg', 0.7); // JPEG 품질을 0.7로 설정
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

// S3 업로드 유틸 함수
export const uploadToS3 = async (file: RcFile, filename=""): Promise<string> => {
  const uu = uuid();
  const fileName = `${uu}`;

  try {
    // 이미지 리사이징 (최대 1000x1000 픽셀)
    // const resizedImage = await resizeImage(file, 1000, 1000);

    const params = {
      Bucket: "doubleup-storage",
      Key: filename || fileName,
      Body: file,
      ContentType: "image/jpeg",
    };

    const command = new PutObjectCommand(params);
    console.log(command);
    const res = await s3Client.send(command);
console.log(res);
    return uu;
  } catch (error) {
    console.error("Error uploading to S3:", error);
    throw error;
  }
};

export const createFile = async (file: RcFile, name: string): Promise<any> => {
  try {
    const base64Key = btoa(encodeURIComponent(name));

    const params = {
      Bucket: "doubleup-storage",
      Key: base64Key,
      Body: file,
      // ContentType: "image/jpeg",
    };

    const command = new PutObjectCommand(params);
    await s3Client.send(command);


    return {
      key: base64Key,
      name: name
    }

    // 여기에 데이터베이스에 fileData를 저장하는 로직을 추가할 수 있습니다.
    // 예: await saveFileDataToDatabase(fileData);


  } catch (error) {
    console.error("Error creating file:", error);
    throw error;
  }
};

export const deleteFile = async (key: string): Promise<boolean> => {
  try {
    const deleteCommand = new DeleteObjectCommand({
      Bucket: "doubleup-storage",
      Key: key,
    });

    await s3Client.send(deleteCommand);

    // 여기에 데이터베이스에서 파일 정보를 삭제하는 로직을 추가할 수 있습니다.
    // 예: await deleteFileDataFromDatabase(key);

    return true;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};
