import React from "react";
import { Form, Input, Button, Typography, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../utils/user";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";
import styled from "styled-components";

const { Title, Text } = Typography;

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 40px;

  flex-direction: column;
  box-sizing: border-box;
`;

const StyledLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #1890ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default function Login() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();

  const onFinish = async (values: { id: string; password: string }) => {
    const result = await loginUser(values.id, values.password);
    console.log(result);
    if (result.success) {
      localStorage.setItem("userInfo__doubleup", JSON.stringify(result.user));
      setUserInfo(result.user);
      message.success("로그인 성공!");
      navigate("/home");
    } else {
      message.error(result.error);
    }
  };

  return (
    <PageContainer>
      <img src="/logos/black2.jpeg" width={250} style={{ margin: "10px 0" }} />
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{
          padding: "2rem",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        {/* <Title
          level={2}
          style={{
            textAlign: "center",

            marginBottom: "1.5rem",
          }}
        >
          로그인
        </Title> */}

        <Form.Item
          name="id"
          rules={[{ required: true, message: "아이디를 입력해주세요!" }]}
        >
          <Input
            prefix={<UserOutlined style={{ marginRight: 5 }} />}
            placeholder="id"
            size="middle"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해주세요!" }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ marginRight: 5 }} />}
            placeholder="비밀번호"
            size="middle"
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{
              width: "100%",
            }}
          >
            로그인
          </Button>
        </Form.Item>

        <Form.Item>
        {/* <StyledLink to="/find-id">아이디찾기</StyledLink> */}
          <StyledLink to="/find-password">비밀번호찾기</StyledLink>
          <StyledLink to="/signup">회원가입하기</StyledLink>
        </Form.Item>
      </Form>
    </PageContainer>
  );
}
