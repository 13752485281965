export const branchOptions = [

    { value: "SYDP", label: "수영본점" },
    { value: "YJDP", label: "양정점" },
    { value: "YSDP", label: "연산점" },
    { value: "ASDP", label: "아시아드점" },
    { value: "DRDP", label: "동래점" },
    { value: "SMDP", label: "서면점" },
    { value: "GADP", label: "광안리점" },
    { value: "JSDP", label: "장산점" },
    { value: "IJDP", label: "인제대점" },
    { value: "KSDP", label: "경성대점" },
    { value: "KBDP", label: "경북대점" },
  ];

  
  
  export const branchSuffix: any = {
    수영본점: "SYDP",
    양정점: "YJDP",
    연산점: "YSDP",
    아시아드점: "ASDP",
    동래점: "DRDP",
    서면점: "SMDP",
    광안리점: "GADP",
    장산점: "JSDP",
    인제대점: "IJDP",
    경성대점: "KSDP",
    경북대점: "KBDP"
  };