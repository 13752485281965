import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, ScanCommand } from "@aws-sdk/lib-dynamodb";

const client = new DynamoDBClient({
    region: "ap-northeast-2",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
    },
  });
  
  const docClient = DynamoDBDocumentClient.from(client);


  export const readPointSum = async () => {
    const params = {
      TableName: "point-sum",
    };
  
    try {
      const result = await docClient.send(new ScanCommand(params));
      const data = result.Items as any[];
  
      return { success: true, data };
    } catch (error) {
      console.error("Error reading data:", error);
      return { success: false, error };
    }
  };
  
  