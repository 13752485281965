import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "../../atom/userInfo";
import { getUserById } from "../../utils/user";

const PrivateRoute = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  useEffect(() => {
    const refreshUserInfo = async () => {
      if (userInfo.id) {
        try {
          const latestUserInfo = await getUserById(userInfo.id);
          if (latestUserInfo) {
            setUserInfo(latestUserInfo);
          }
        } catch (error) {
          console.error("사용자 정보 갱신 중 오류 발생:", error);
        }
      }
    };

    refreshUserInfo();
  }, []); // 컴포넌트 마운트 시 한 번만 실행
  return userInfo?.id ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
