import {
  Button,
  Descriptions,
  Divider,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { userInfoState } from "../../atom/userInfo";
import { useRecoilState } from "recoil";
import { branchOptions, branchSuffix } from "../../constants/branch";
import { getUserById, updateUser } from "../../utils/user";
import { Scanner } from "@yudiel/react-qr-scanner";

const { Option } = Select;
const { Title } = Typography;

export default function QrEditModal({ isOpen, closeModal, setIsOpen }: any) {
  const [scannedUserInfo, setScannedUserInfo] = useState<any>(null);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [scannedScore, setScannedScore] = useState(0);
  const [scannedCoin, setScannedCoin] = useState(0);
  const [scannedBranch, setScannedBranch] = useState("");

  const [scannedBranchScore, setScannedBranchScore] = useState("");
  const [scannedPoint, setScannedPoint] = useState(0);

  const handleQRScanModalCancel = () => {
    setIsOpen(false);
    setScannedUserInfo(null);
  };

  const handleQrScan = async (result: any) => {
    try {
      const info = JSON.parse(result[0].rawValue);
      const user = await getUserById(info.userId);
      console.log(user);
      if (user) {
        setScannedUserInfo(user);
        setScannedScore(user.score?.[userInfo.branch] || 0);
        setScannedCoin(user.coin);
        setScannedBranch(userInfo.branch);
        setScannedBranchScore(userInfo.branch);
        setScannedPoint(user.point[userInfo.branch]);

        message.success("사용자 정보를 성공적으로 불러왔습니다.");
      } else {
        message.error("사용자 정보를 찾을 수 없습니다.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error("사용자 정보를 불러오는데 실패했습니다.");
    }
  };

  const handleFormSubmit = async () => {
    try {
      const updatedUser = {
        ...scannedUserInfo,
        score: {
          ...(scannedUserInfo.score || {}),
          [scannedBranch]: scannedScore,
        },
        coin: scannedCoin,
        point: {
          ...scannedUserInfo.point,
          [scannedBranch]: scannedPoint,
        },
      };
      await updateUser(updatedUser, userInfo, true);
      message.success("사용자 정보가 성공적으로 업데이트되었습니다.");
      setScannedUserInfo(updatedUser);
      closeModal();
    } catch (error) {
      console.error("Error updating user data:", error);
      message.error("사용자 정보 업데이트에 실패했습니다.");
    }
  };

  const adjustValue = (
    setter: React.Dispatch<React.SetStateAction<number>>,
    amount: number
  ) => {
    setter((prev) => Math.max(0, prev + amount));
  };

  const renderAdjustButtons = (
    setter: React.Dispatch<React.SetStateAction<number>>,
    values: number[]
  ) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Button.Group style={{ display: "flex", width: "100%" }}>
        {values
          .filter((v) => v < 0)
          .map((value) => (
            <Button
              key={value}
              onClick={() => adjustValue(setter, value)}
              style={{ width: "100%" }}
            >
              {value}
            </Button>
          ))}
      </Button.Group>
      <Button.Group style={{ display: "flex", width: "100%" }}>
        {values
          .filter((v) => v > 0)
          .map((value) => (
            <Button
              key={value}
              onClick={() => adjustValue(setter, value)}
              style={{ width: "100%" }}
            >
              +{value}
            </Button>
          ))}
      </Button.Group>
    </div>
  );

  return (
    <Modal
      title={scannedUserInfo ? "QR 스캔 결과" : "QR 코드 스캔"}
      open={isOpen}
      onCancel={handleQRScanModalCancel}
      footer={null}
      width={600}
      centered
    >
      {scannedUserInfo ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="ID">
              {scannedUserInfo.id}
            </Descriptions.Item>
            <Descriptions.Item label="이름">
              {scannedUserInfo.username}
            </Descriptions.Item>
            <Descriptions.Item label="닉네임">
              {scannedUserInfo.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="지점">
              {scannedUserInfo.branch}
            </Descriptions.Item>
          </Descriptions>
          <Space
            direction="vertical"
            style={{ width: "100%", marginTop: 20, gap: 10 }}
          >
            <div>
              <Title level={4}>랭킹점수</Title>
              <label>지점</label>
              <Select
                style={{ width: "100%", marginBottom: 20 }}
                value={scannedBranchScore}
                onChange={(value) => {
                  setScannedBranchScore(value);

                  setScannedScore(scannedUserInfo.score?.[value] || 0);
                }}
                disabled={!userInfo.isRootAdmin}
              >
                {branchOptions.map((option) => (
                  <Option key={option.label} value={option.label}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <label>
                랭킹점수 (현재 :{" "}
                {scannedUserInfo.score?.[scannedBranchScore] || 0})
              </label>
              <Space direction="vertical" style={{ width: "100%" }}>
                <InputNumber
                  style={{ width: "100%" }}
                  value={scannedScore}
                  onChange={(value) => setScannedScore(value || 0)}
                />
                {renderAdjustButtons(
                  setScannedScore,
                  [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5]
                )}
              </Space>
            </div>
            <Divider />
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                통합포인트
              </Title>
              <label>통합 포인트 (현재 : {scannedUserInfo.coin})</label>
              <Space direction="vertical" style={{ width: "100%" }}>
                <InputNumber
                  disabled={!userInfo.isOwner}
                  style={{ width: "100%" }}
                  value={scannedCoin}
                  onChange={(value) => setScannedCoin(value || 0)}
                  addonAfter="DP"
                />
                <Button.Group style={{ display: "flex", width: "100%" }}>
                  <Button
                    disabled={!userInfo.isOwner}
                    onClick={() => adjustValue(setScannedCoin, -10)}
                    style={{ width: "100%" }}
                  >
                    -10
                  </Button>
                  <Button
                    disabled={!userInfo.isOwner}
                    onClick={() => adjustValue(setScannedCoin, 10)}
                    style={{ width: "100%" }}
                  >
                    +10
                  </Button>
                </Button.Group>
              </Space>
            </div>
            <Divider />
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                포인트
              </Title>
              <label>지점</label>
              <Select
                style={{ width: "100%", marginBottom: 20 }}
                value={scannedBranch}
                onChange={(value) => {
                  setScannedBranch(value);
                  console.log(scannedUserInfo);
                  setScannedPoint(scannedUserInfo.point[value]);
                }}
                disabled={!userInfo.isRootAdmin}
              >
                {branchOptions.map((option) => (
                  <Option key={option.label} value={option.label}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <label>
                지점 포인트 (현재: {scannedUserInfo.point?.[scannedBranch] || 0}
                ){" "}
              </label>
              <Space direction="vertical" style={{ width: "100%" }}>
                <InputNumber
                  style={{ width: "100%" }}
                  value={scannedPoint}
                  onChange={(value) => setScannedPoint(value || 0)}
                  addonAfter={branchSuffix[scannedBranch]}
                />
                {renderAdjustButtons(
                  setScannedPoint,
                  [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5]
                )}
              </Space>
            </div>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              style={{ width: "100%" }}
            >
              정보 업데이트
            </Button>
          </Space>
        </Space>
      ) : (
        <Scanner onScan={handleQrScan} />
      )}
    </Modal>
  );
}
