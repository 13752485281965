import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";
import {
  Upload,
  message,
  Avatar,
  Descriptions,
  Typography,
  Row,
  Col,
  List,
  Button,
  Space,
  Modal,
  Tag,
  Input,
  Select,
  InputNumber,
  Form,
  Divider,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  UserOutlined,
  UploadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  QrcodeOutlined,
  ReloadOutlined,
  EditOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { uploadToS3 } from "../utils/s3";
import QRCode from "qrcode.react";
import {
  formatTimestamp,
  getChangeColor,
  getFieldColor,
  getUserLogs,
} from "../utils/log";
import { format, parse } from "date-fns";
import { ko } from "date-fns/locale";
import {
  getUserById,
  updateProfileImage,
  updateNickname,
  updateBranch,
  updateUser,
  updatePassword,
} from "../utils/user";
import styled from "styled-components";
import { branchOptions, branchSuffix } from "../constants/branch";
import { getRank } from "../utils/rank";
import { useNavigate } from "react-router-dom";
import { Scanner } from "@yudiel/react-qr-scanner";
import QrEditModal from "../components/modal/QrEditModal";
import { readPointSum } from "../utils/pointSum";
import LogList from "../components/LogList";

const { Title, Text } = Typography;
const { Option } = Select;

const Card = styled.div``;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useRecoilState<any>(userInfoState);
  const [isQRModalVisible, setIsQRModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isBranchPointsModalVisible, setIsBranchPointsModalVisible] =
    useState(false);

  const [isPointSumsModalVisible, setIsPointSumsModalVisible] = useState(false);

  const [isBranchScoreModalVisible, setIsBranchScoreModalVisible] =
    useState(false);
  const [userLogs, setUserLogs] = useState<any[]>([]);
  const [isQRScanModalVisible, setIsQRScanModalVisible] = useState(false);
  const [editNickname, setEditNickname] = useState("");
  const [editBranch, setEditBranch] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [pointSums, setPointSums] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserLogs();
  }, [userInfo.id]);

  useEffect(() => {
    fetchPointSum();
  }, [])

  const fetchPointSum = async() => {
    try {
      const pointSums = await readPointSum();
      console.log(pointSums)
      setPointSums(pointSums.data);
    } catch (error) {
      
    }
  }
  const fetchUserLogs = async () => {
    try {
      const logs = await getUserLogs(userInfo.id);
      if (!logs) return;
      setUserLogs(logs);
    } catch (error) {
      message.error("로그 조회에 실패했습니다.");
    }
  };

  const handleImageUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const file = info.file as RcFile;
    if (!file) return;

    try {
      const imageName = await uploadToS3(file);
      await updateProfileImage(userInfo.id, imageName);
      setUserInfo({ ...userInfo, profileImage: imageName });
      message.success(`프로필 사진을 변경했습니다.`);
    } catch (error) {
      message.error(`프로필 사진 변경에 실패했습니다.`);
    }
  };

  const showEditModal = () => {
    setEditNickname(userInfo.nickname);
    setEditBranch(userInfo.branch);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/;
    return regex.test(password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    if (value && !validatePassword(value)) {
      setPasswordError(
        "비밀번호는 영문과 숫자를 포함하여 8자리 이상이어야 합니다."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleUserInfoUpdate = async () => {
    try {
      let modify = false;

      if (!userInfo.isModified && editNickname !== userInfo.nickname) {
        await updateNickname(userInfo.id, editNickname);
        modify = true;
      }

      if (editBranch !== userInfo.branch) {
        await updateBranch(userInfo.id, editBranch, false);
      }

      if (newPassword) {
        if (newPassword !== confirmPassword) {
          message.error("비밀번호가 일치하지 않습니다.");
          return;
        }
        if (!validatePassword(newPassword)) {
          message.error(
            "비밀번호는 영문과 숫자를 포함하여 8자리 이상이어야 합니다."
          );
          return;
        }
        await updatePassword(userInfo.id, newPassword);
        // message.success("비밀번호가 성공적으로 변경되었습니다.");
      }

      setUserInfo({
        ...userInfo,
        nickname: editNickname,
        isModified: modify,
        branch: userInfo.branch,
      });

      if (editBranch !== userInfo.branch) {
        message.success(
          "기본 지점 변경을 요청했습니다. 관리자의 승인 후 지점이 변경됩니다."
        );
      } else {
        message.success("사용자 정보가 성공적으로 변경되었습니다.");
      }

      setIsEditModalVisible(false);
      setNewPassword("");
      setConfirmPassword("");
      setPasswordError("");
    } catch (error) {
      message.error("사용자 정보 변경에 실패했습니다.");
    }
  };

  const generateQRValue = () => {
    const qrData = {
      userId: userInfo.id,
      username: userInfo.username,
      date: new Date().toISOString(),
    };
    return JSON.stringify(qrData);
  };

  const reloadInfo = async () => {
    try {
      const id =
        userInfo?.id ||
        JSON.parse(localStorage.getItem("userInfo_doubleup") || "{}")?.id;
      if (!id) {
        message.warning(
          "사용자 정보를 불러올 수 없습니다. 잠시 후 다시 시도해주세요."
        );
        return;
      }
      const res = await getUserById(id);
      fetchUserLogs();
      fetchPointSum();
      setUserInfo(res);
      message.success("정보를 새로 불러왔습니다.");
    } catch (error) {
      message.error("정보를 불러오는데 실패했습니다.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userInfo_doubleup");
    setUserInfo({});
    navigate("/login");
  };

  const handleQrScanModalClose = () => {
    setIsQRScanModalVisible(false);
    // reloadInfo();
  };
  return (
    <Content
      style={{
        padding: "24px",
        maxWidth: "1200px",
        margin: "0 auto",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Card>
            <Panel>
              <Space
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <Avatar
                    size={50}
                    icon={<UserOutlined />}
                    src={
                      userInfo.profileImage
                        ? `${process.env.REACT_APP_S3_STORAGE}/${
                            userInfo.profileImage
                          }?t=${new Date()}`
                        : ""
                    }
                    style={{ border: "2px solid #ffd700", minWidth: 50 }}
                  />
                  <Title
                    level={2}
                    style={{ color: "#ffd700", margin: 0, marginLeft: 10 }}
                  >
                    {userInfo?.nickname}
                  </Title>
                  <Button
                    icon={<EditOutlined />}
                    type="text"
                    onClick={showEditModal}
                    style={{ color: "#ffd700" }}
                  />
                </div>
                {(userInfo.isAdmin || userInfo.isPointAdmin) && (
                  <Button
                    onClick={() => setIsQRScanModalVisible(true)}
                    icon={<QrcodeOutlined />}
                  >
                    QR 코드 인식
                  </Button>
                )}
              </Space>

              <Descriptions
                bordered
                column={1}
                size="small"
                labelStyle={{ width: "35%", fontSize: 13 }}
                style={{ width: "100%", marginTop: "20px" }}
              >
                <Descriptions.Item label="아이디">
                  {userInfo.id}
                </Descriptions.Item>
                <Descriptions.Item label="이름">
                  {userInfo.username}
                </Descriptions.Item>
                {(userInfo.isAdmin || userInfo.isRootAdmin) && (
                  <Descriptions.Item label="역할">
                    {userInfo.isRootAdmin ? "대표관리자" : "지점관리자"}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="랭킹점수">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>{userInfo?.score?.[userInfo.branch] ?? 0}</div>
                    <Button
                      size="small"
                      onClick={() => setIsBranchScoreModalVisible(true)}
                      type="text"
                      style={{ padding: 0, color: "#888" }}
                    >
                      모든 랭킹점수 보기
                    </Button>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="랭킹등급">
                  {getRank(userInfo?.score?.[userInfo.branch] ?? 0)}
                </Descriptions.Item>
                <Descriptions.Item label="지점">
                  {userInfo.branch}
                </Descriptions.Item>
                <Descriptions.Item label="통합포인트">
                  {userInfo.coin || 0} DXG
                </Descriptions.Item>
                <Descriptions.Item label="지점포인트">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      {userInfo.point[userInfo.branch]}{" "}
                      {branchSuffix[userInfo.branch || "수영본점"]}
                    </div>
                    <Button
                      size="small"
                      onClick={() => setIsBranchPointsModalVisible(true)}
                      type="text"
                      style={{ padding: 0, color: "#888" }}
                    >
                      모든 포인트 보기
                    </Button>
                  </div>
                </Descriptions.Item>
                {userInfo.isRootAdmin && <Descriptions.Item label="DXG 총 사용량">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      {pointSums.filter((i: any)=> i.id === "통합포인트")[0]?.sum}{" "}
                      DXG
                    </div>
                  </div>
                </Descriptions.Item>}
               {userInfo.isAdmin&& <Descriptions.Item label="DP 총 사용량">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      {pointSums.filter((i: any)=> i.id === userInfo.branch)[0]?.sum}{" "}
                      {branchSuffix[userInfo.branch || "수영본점"]}
                    </div>
                    {userInfo.isRootAdmin && <Button
                      size="small"
                      onClick={() => setIsPointSumsModalVisible(true)}
                      type="text"
                      style={{ padding: 0, color: "#888" }}
                    >
                      모든 포인트 보기
                    </Button>}
                  </div>
                </Descriptions.Item>}

                
              </Descriptions>
              <Space.Compact>
                <Button
                  onClick={() => setIsQRModalVisible(true)}
                  icon={<QrcodeOutlined />}
                  style={{ width: "100%" }}
                >
                  QR
                </Button>
                <Button
                  onClick={reloadInfo}
                  icon={<ReloadOutlined />}
                  style={{ width: "100%" }}
                >
                  새로고침
                </Button>
                <Button
                  onClick={handleLogout}
                  icon={<LogoutOutlined />}
                  style={{ width: "100%" }}
                >
                  로그아웃
                </Button>
              </Space.Compact>
            </Panel>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card>
            <LogList logs={userLogs} />
          </Card>
        </Col>
      </Row>

      <Modal
        visible={isQRModalVisible}
        onCancel={() => setIsQRModalVisible(false)}
        footer={null}
        width={"fit-content"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            background: "#fff",
            width: "fit-content",
            margin: "auto",
            borderRadius: 20,
          }}
        >
          <QRCode value={generateQRValue()} size={256} />
        </div>
      </Modal>

      <Modal
        title="정보 수정"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        centered
        onOk={handleUserInfoUpdate}
      >
        <Divider style={{ marginTop: 14, marginBottom: 14 }} />
        <Form layout="vertical">
          <Form.Item label="프로필 사진">
            <Upload
              name="avatar"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={handleImageUpload}
            >
              <Button icon={<UploadOutlined />}>프로필 사진 변경하기</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="닉네임">
            <Input
              value={editNickname}
              onChange={(e) => setEditNickname(e.target.value)}
              disabled={userInfo.isModified}
            />
          </Form.Item>
          <Form.Item label="비밀번호 변경">
            <Space.Compact
              direction="vertical"
              style={{ width: "100%", gap: 5 }}
            >
              <Input.Password
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="새 비밀번호"
              />
              <Input.Password
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="비밀번호 확인"
              />
            </Space.Compact>
          </Form.Item>
          <Form.Item label="기본 지점">
            <Select
              value={editBranch}
              onChange={(value) => setEditBranch(value)}
              style={{ width: "100%" }}
            >
              {branchOptions.map((option) => (
                <Option key={option.label} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
            <div style={{ marginTop: 10, color: "#777" }}>
              지점 변경은 관리자의 승인 후 변경됩니다.
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={isBranchPointsModalVisible}
        onCancel={() => setIsBranchPointsModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={branchOptions}
          renderItem={(item) => (
            <List.Item>
              <Text>{item.label}:</Text>
              <Text strong>
                {userInfo.point?.[item.label] || 0} {branchSuffix[item.label]}
              </Text>
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        visible={isBranchScoreModalVisible}
        onCancel={() => setIsBranchScoreModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={branchOptions}
          renderItem={(item) => (
            <List.Item>
              <Text>{item.label}:</Text>
              <Text strong>{userInfo.score?.[item.label] || 0}</Text>
            </List.Item>
          )}
        />
      </Modal>
      <Modal
        visible={isPointSumsModalVisible}
        onCancel={() => setIsPointSumsModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={branchOptions}
          renderItem={(item) => (
            <List.Item>
              <Text>{item.label}:</Text>
              <Text strong>{pointSums && pointSums.filter((i: any) => i.id === item.label)[0]?.sum} {branchSuffix[item.label]}</Text>
            </List.Item>
          )}
        />
      </Modal>
      
      <QrEditModal
        isOpen={isQRScanModalVisible}
        closeModal={handleQrScanModalClose}
        setIsOpen={setIsQRScanModalVisible}
      />
    </Content>
  );
};

export default Profile;
