import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  TrophyOutlined,
  UserOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  LockOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "../atom/userInfo";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

interface NavigationBarProps {
  current: string;
}

const NavBar = styled.nav`
  background: #1a1a2ed3;
  border-radius: 10px;
  position: fixed;
  z-index: 999;
  bottom: 10px;
  box-sizing: border-box;
  width: 95vw;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const NavList = styled.ul`
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: ${(props) => (props.isActive ? "#ffd700" : "#ffffff")};
  cursor: pointer;
  transition: 0.1s all;
  &:hover {
    color: #ffd900a0;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .anticon {
      margin-right: 8px;
    }
  }
`;

const NavText = styled.span`
  font-size: 12px;
  margin-top: 4px;

  @media (min-width: 768px) {
    font-size: 14px;
    margin-top: 0;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    color: #40a9ff;
  }

  .anticon {
    margin-right: 8px;
  }
`;

const ResponsiveNavItem = ({ icon, title, dest }: any) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === `/${dest}`;

  return (
    <NavItem isActive={isActive} onClick={() => navigate(`/${dest}`)}>
      {icon}
      <NavText>{title}</NavText>
    </NavItem>
  );
};

const NavigationBar: React.FC<NavigationBarProps> = ({ current }) => {
  const [currentLocation, setCurrentLocation] = useState("");
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("userInfo_doubleup");
    setUserInfo({});
    navigate("/login");
  };

  useEffect(() => {
    // console.log(location.pathname.split("/")[1])
    setCurrentLocation(location.pathname.split("/")[1]);
  }, [location]);

  if (
    ![
      "home",
      "ranking",
      "profile",
      "location",
      "news",
      "data",
      "admin",
      "rally"
    ].includes(currentLocation)
  ) {
    return null;
  }

  return (
    <NavBar>
      <NavList>
        <ResponsiveNavItem icon={<HomeOutlined />} title="홈" dest="home" />
        <ResponsiveNavItem
          icon={<TrophyOutlined />}
          title="랭킹"
          dest="ranking"
        />
        <ResponsiveNavItem
          icon={<UserOutlined />}
          title="프로필"
          dest="profile"
        />
        <ResponsiveNavItem
          icon={<EnvironmentOutlined />}
          title="지점"
          dest="location"
        />
        {/* <ResponsiveNavItem
          icon={<FileTextOutlined />}
          title="대회소식"
          dest="news"
        /> */}
        {userInfo.dataAccessibility && (
          <ResponsiveNavItem
            icon={<DatabaseOutlined />}
            title="자료실"
            dest="data"
          />
        )}

        <ResponsiveNavItem
          icon={<CalendarOutlined />}
          title="대회예약"
          dest="rally"
        />

        {(userInfo.isAdmin || userInfo.isPointAdmin) && (
          <ResponsiveNavItem
            icon={<LockOutlined />}
            title="관리자"
            dest="admin"
          />
        )}
        {/* <NavItem isActive={false} onClick={handleLogout}>
          <LogoutOutlined />
          <NavText>로그아웃</NavText>
        </NavItem> */}
      </NavList>
    </NavBar>
  );
};

export default NavigationBar;
