import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Button, message, Modal, Form, Input, Upload, Space } from "antd";
import { UploadOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDate } from "../../utils/date";
import { createFile, uploadToS3 } from "../../utils/s3";
import { createData, deleteData, readData, updateData } from "../../utils/data";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

interface Data {
  id: string;
  title: string;
  content: string;
  imageUrl?: string;
}

export default function DataAdmin() {
  const [dataList, setDataList] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingData, setEditingData] = useState<Data | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef<ReactQuill>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result: any = await readData();
      if (result.success) {
        setDataList(result.data);
      } else {
        message.error("데이터를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("데이터를 불러오는데 실패했습니다.");
    }
    setLoading(false);
  };

  const handleEdit = (data: Data) => {
    setEditingData(data);
    form.setFieldsValue(data);
    setEditorContent(data.content);
    setFileList(data.imageUrl ? [{ url: data.imageUrl }] : []);
    setIsModalVisible(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await deleteData(id);
      if (result.success) {
        message.success("데이터가 삭제되었습니다.");
        setDataList(dataList.filter((item) => item.id !== id));
      } else {
        message.error("데이터 삭제에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      message.error("데이터 삭제 중 오류가 발생했습니다.");
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      let imageUrl = editingData?.imageUrl;

      const files = [];
      if (fileList.length > 0) {
        for (const file of fileList) {
          if (file.originFileObj) {
            const fileData = await createFile(file.originFileObj, file.name);
            files.push(fileData);
          }
        }
      }

      const data = {
        ...editingData,
        ...values,
        content: editorContent,
        imageUrl,
        files: files,
      };
      let result;
      if (editingData) {
        result = await updateData(data);
      } else {
        result = await createData(data);
      }

      if (result.success) {
        message.success(
          editingData
            ? "데이터가 업데이트되었습니다."
            : "데이터가 추가되었습니다."
        );
        setIsModalVisible(false);
        fetchData();
      } else {
        message.error(
          editingData
            ? "데이터 업데이트에 실패했습니다."
            : "데이터 추가에 실패했습니다."
        );
      }
    } catch (error) {
      console.error("Error saving data:", error);
      message.error("데이터 저장 중 오류가 발생했습니다.");
    }
  };

  const handleAdd = () => {
    setEditingData(null);
    form.resetFields();
    setEditorContent("");
    setFileList([]);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "마지막으로 수정된 날짜",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
      render: (v: any) => formatDate(v),
    },
    {
      title: "작업",
      key: "action",
      render: (_: any, record: Data) => (
        <Space.Compact>
          <Button onClick={() => handleEdit(record)} size="small"             icon={<EditOutlined />}
          >수정</Button>
          <Button
            danger
            size="small"
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}

          >
            삭제
          </Button>
        </Space.Compact>
      ),
    },
  ];

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files) {
        const file = input.files[0];
        try {
          const imageName = await uploadToS3(file as any);
          const imageUrl = `${process.env.REACT_APP_S3_STORAGE}/${imageName}`;
          const quill = quillRef.current?.getEditor();
          if (quill) {
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", imageUrl);
          }
        } catch (error) {
          console.error("Failed to upload image:", error);
          message.error("이미지 업로드에 실패했습니다.");
        }
      }
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const handleFileChange = (info: any) => {
    let fileList = [...info.fileList];
    // fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  return (
    <Wrapper>
      <Button

        onClick={handleAdd}
        style={{ marginBottom: 20 }}
        icon={<PlusOutlined />}
      >
        데이터 추가
      </Button>
      <Table
        dataSource={dataList}
        columns={columns}
        rowKey="id"
        loading={loading}
        scroll={{ x: true}}
        size={isMobile ? "small" : "middle"}
      />

      <Modal
        title={editingData ? "데이터 편집" : "데이터 추가"}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: "제목을 입력하세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="내용" name="content">
            <ReactQuill
              ref={quillRef}
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              theme="snow"
            />
          </Form.Item>
          <Form.Item label="파일 추가" name="imageUrl">
            <Upload
              fileList={fileList}
              onChange={handleFileChange}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>파일 업로드</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </Wrapper>
  );
}
