import React, { useEffect, useState } from 'react';
import { List, Typography, Space, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { readNews } from '../utils/news';
import { readData } from '../utils/data';

const { Title } = Typography;

interface News {
  id: string;
  title: string;
  lastModifiedAt: string;
}

export default function Data() {
  const [newsList, setNewsList] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setLoading(true);
    const result = await readData();
    if (result.success) {
      setNewsList(result.data || []);
    } else {
      console.error("Failed to fetch news:", result.error);
      // You might want to show an error message to the user here
    }
    setLoading(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleNewsClick = (id: string) => {
    navigate(`/data/${id}`);
  };

  if (loading) {
    return <Spin size="large" style={{ margin: "auto"}}/>;
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex', padding: '24px', width: "100%", maxWidth: 1200, boxSizing: "border-box", margin: "0 auto"}}>
      <Title level={2}>자료실</Title>
      <List
        dataSource={newsList}
        renderItem={(item) => (
          <List.Item 
            key={item.id}
            onClick={() => handleNewsClick(item.id)}
            style={{ cursor: 'pointer' }}
          >
            <List.Item.Meta
              title={item.title}
              description={`마지막 수정: ${formatDate(item.lastModifiedAt)}`}
            />
          </List.Item>
        )}
      />
    </Space>
  );
}