import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  PutCommand,
  DeleteCommand,
  ScanCommand,
  QueryCommand,
  GetCommand,
  UpdateCommand,
} from "@aws-sdk/lib-dynamodb";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});
const docClient = DynamoDBDocumentClient.from(client);

const TABLE_NAME = "rally";

export interface Rally {
  id: string;
  name: string;
  date: string;
  gsiPartitionKey: string;
}

export const createRally = async (rally: Omit<Rally, 'gsiPartitionKey'>): Promise<Rally> => {
  const newRally: Rally = {
    ...rally,
    gsiPartitionKey: 'ALL',
  };
  
  await docClient.send(
    new PutCommand({
      TableName: TABLE_NAME,
      Item: newRally,
    })
  );
  
  return newRally;
};

export const updateRally = async (rally: Partial<Rally>): Promise<void> => {
  const updateExpression: any = [];
  const expressionAttributeValues: { [key: string]: any } = {};
  const expressionAttributeNames: { [key: string]: string } = {};

  
  Object.keys(rally).forEach((key, index) => {
    if (key !== 'id' && key !== 'gsiPartitionKey') {
      updateExpression.push(`#field${index} = :value${index}`);
      expressionAttributeValues[`:value${index}`] = rally[key as keyof Rally];
      expressionAttributeNames[`#field${index}`] = key;
    }
  });

  console.log(expressionAttributeNames, expressionAttributeValues, updateExpression)

  await docClient.send(
    new UpdateCommand({
      TableName: TABLE_NAME,
      Key: { id: rally.id },
      UpdateExpression: `SET ${updateExpression.join(', ')}`,
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: expressionAttributeNames,
    })
  );
};

export const deleteRally = async (id: string): Promise<void> => {
  await docClient.send(
    new DeleteCommand({
      TableName: TABLE_NAME,
      Key: { id },
    })
  );
};

export const getAllRallies = async (): Promise<Rally[]> => {
  const { Items } = await docClient.send(
    new QueryCommand({
      TableName: TABLE_NAME,
      IndexName: "gsiPartitionKey-date-index",
      KeyConditionExpression: "gsiPartitionKey = :pk",
      ExpressionAttributeValues: {
        ":pk": "ALL",
      },
      ScanIndexForward: false, // 내림차순 정렬
    })
  );

  return Items as Rally[];
};

export const getRallyById = async (id: string): Promise<Rally | null> => {
  try {
    const { Item } = await docClient.send(
      new GetCommand({
        TableName: TABLE_NAME,
        Key: { id },
      })
    );

    if (!Item) {
      return null;
    }

    return Item as Rally;
  } catch (error) {
    console.error("Error getting rally by ID:", error);
    throw new Error("Failed to get rally");
  }
};

export const addReservationToRally = async (rallyId: string, reservation: any): Promise<void> => {
  try {
    await docClient.send(
      new UpdateCommand({
        TableName: TABLE_NAME,
        Key: { id: rallyId },
        UpdateExpression: "SET reservations = list_append(if_not_exists(reservations, :empty_list), :reservation)",
        ExpressionAttributeValues: {
          ":empty_list": [],
          ":reservation": [reservation],
        },
      })
    );
  } catch (error) {
    console.error("Error adding reservation to rally:", error);
    throw new Error("Failed to add reservation");
  }
};

export const cancelReservation = async (rallyId: string, reservationId: string): Promise<void> => {
  try {
    const { Item } = await docClient.send(
      new GetCommand({
        TableName: TABLE_NAME,
        Key: { id: rallyId },
      })
    );

    if (!Item) {
      throw new Error("Rally not found");
    }

    const rally = Item as any;
    const updatedReservations = rally.reservations?.filter(
      (reservation: any) => reservation.id !== reservationId
    ) || [];

    await docClient.send(
      new UpdateCommand({
        TableName: TABLE_NAME,
        Key: { id: rallyId },
        UpdateExpression: "SET reservations = :reservations",
        ExpressionAttributeValues: {
          ":reservations": updatedReservations,
        },
      })
    );
  } catch (error) {
    console.error("Error canceling reservation:", error);
    throw new Error("Failed to cancel reservation");
  }
};
