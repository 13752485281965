import React, { useEffect, useState } from "react";
import { getUserById } from "../../utils/user";
import {
  Col,
  Descriptions,
  Form,
  InputNumber,
  List,
  message,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { branchOptions } from "../../constants/branch";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import {
  formatTimestamp,
  getChangeColor,
  getFieldColor,
  getUserRankLogs,
} from "../../utils/log";
const { Title, Text } = Typography;

export default function UserRankPopup() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>(null);
  const [userLogs, setUserLogs] = useState<any>([]);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    if (id) {
      fetchUser(id);
      fetchUserRankLogs(id);
    } 
  }, []);

  const fetchUser = async (id: string) => {
    try {
      const fetchedUser = await getUserById(id);
      if (fetchedUser) {
        setUserData(fetchedUser);
      }
    } catch (error) {
      console.error("사용자 정보 조회에 실패했습니다:", error);
      message.error("사용자 정보 조회에 실패했습니다.");
    }
    setLoading(false);
  };

  const fetchUserRankLogs = async (id: string) => {
    const res = await getUserRankLogs(id);
    console.log(res);
    setUserLogs(res)
  }
  return (
    <div style={{ padding: 20, boxSizing: "border-box" }}>
      <Title>랭킹</Title>
      <Descriptions bordered column={1} labelStyle={{ width: "30%" }}>
        <Descriptions.Item label="ID">{userData?.id}</Descriptions.Item>
        <Descriptions.Item label="닉네임">
          {userData?.nickname}
        </Descriptions.Item>
        <Descriptions.Item label="사용자명">
          {userData?.username}
        </Descriptions.Item>
        {branchOptions.map((branch: any) => (
          <Descriptions.Item
            key={branch.label}
            label={`${branch.label} 랭킹점수`}
          >
            {userData?.score?.[branch.label] || 0}
          </Descriptions.Item>
        ))}
        
      </Descriptions>

      <List
        style={{ padding: "0 10px", marginTop: 30}}
          dataSource={userLogs}
          renderItem={(item: any) => (
            <List.Item style={{ borderBottom: "1px solid #303030" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Tag color={getFieldColor(item.field)}>
                      {item.field.startsWith("point_")
                        ? `POINT (${
                            branchOptions.find(
                              (b) => b.label === item.field.split("_")[1]
                            )?.label
                          })`
                        : item.field.startsWith("score_")
                        ? `SCORE (${
                            branchOptions.find(
                              (b) => b.label === item.field.split("_")[1]
                            )?.label
                          })`
                        : item.field
                            .toUpperCase()
                            .replace("COIN", "통합포인트")}
                    </Tag>
                    <Text style={{ color: "#d9d9d9" }}>
                      {item.oldValue || 0} → {item.newValue || 0}
                      {item.field === "branch"
                        ? ` (${
                            branchOptions.find((b) => b.value === item.oldValue)
                              ?.label
                          } → 
                               ${
                                 branchOptions.find(
                                   (b) => b.value === item.newValue
                                 )?.label
                               })`
                        : ""}
                    </Text>
                    {item.reason && (
                      <Text style={{ marginLeft: 10 }}>({item.reason})</Text>
                    )}
                  </div>
                  <Text
                    strong
                    style={{ color: getChangeColor(item.changeAmount) }}
                  >
                    {item.changeAmount > 0 ? (
                      <ArrowUpOutlined />
                    ) : item.changeAmount < 0 ? (
                      <ArrowDownOutlined />
                    ) : null}
                    {item.changeAmount > 0 ? "+" : ""}
                    {item.changeAmount}
                  </Text>
                </div>
                <Text
                  type="secondary"
                  style={{ fontSize: "0.8em", color: "#8c8c8c" }}
                >
                  {formatTimestamp(item.timestamp)}
                </Text>
              </Space>
            </List.Item>
          )}
        />
    </div>
  );
}
