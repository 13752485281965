import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../utils/user";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";

export default function Index() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const autoLogin = async () => {
    try {
      const l = localStorage.getItem("userInfo_doubleup");
      if (l) {
        const id = JSON.parse(l)?.id;
        if (id) {
          const res = await getUserById(id);
          if (res) {
            setUserInfo(res);
            navigate("/home");
            return;
          }
        }
      }
      navigate("/login");
    } catch (error) {
      navigate("/login");
    }
  };
  useEffect(() => {
    autoLogin();
  }, []);
  return <div></div>;
}
