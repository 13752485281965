import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  QueryCommand,
  PutCommand,
  DeleteCommand,
} from "@aws-sdk/lib-dynamodb";
import { format, parse } from "date-fns";
import { ko } from "date-fns/locale";

import uuid from "react-uuid";
import { branchOptions } from "../constants/branch";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const docClient = DynamoDBDocumentClient.from(client);


export const formatTimestamp = (timestamp: string) => {
  try {
    const parsedDate = parse(
      timestamp,
      "yyyy-MM-dd HH:mm:ss.SSS 'KST'",
      new Date()
    );
    return format(parsedDate, "yyyy년 MM월 dd일 HH:mm:ss", { locale: ko });
  } catch (error) {
    console.error("Timestamp formatting error:", error);
    return timestamp;
  }
};

export const getChangeColor = (changeAmount: number) => {
  return changeAmount > 0
    ? "#00a536"
    : changeAmount < 0
    ? "#ff4d4f"
    : "#8c8c8c";
};

export const getFieldColor = (field: string) => {
  if (field.startsWith("point_")) return "blue";
  if (field.startsWith("score_")) return "green";
  switch (field) {
    case "userDelete":
      return "red";
    case "coin":
      return "gold";
    case "score":
      return "green";
    case "branch":
      return "purple";
    default:
      return "default";
  }
};


const getKRCurrent = () => {

  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const korNow = new Date(utc + koreaTimeDiff);

  const formattedDate =
    korNow.getFullYear() +
    "-" +
    String(korNow.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(korNow.getDate()).padStart(2, "0") +
    " " +
    String(korNow.getHours()).padStart(2, "0") +
    ":" +
    String(korNow.getMinutes()).padStart(2, "0") +
    ":" +
    String(korNow.getSeconds()).padStart(2, "0") +
    "." +
    String(korNow.getMilliseconds()).padStart(3, "0") +
    " KST";

    return formattedDate;
}


export const createLog = async (
  userId: string,
  field: string,
  oldValue: number,
  newValue: number,
  fromBranch: string,
  fromUsername: string,
  username: string,
  nickname: string,
  branch: string,
  reason?: string,
) => {
  // 현재 시간을 한국 시간대(KST)로 변환
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const korNow = new Date(utc + koreaTimeDiff);



  const logItem = {
    id: uuid(),
    userId,
    timestamp: getKRCurrent(),
    field,
    oldValue: oldValue || 0,
    newValue: newValue || 0,
    changeAmount: newValue - oldValue,
    fromBranch: fromBranch,
    fromUsername: fromUsername,
    reason: reason || "",
    username: username,
    nickname: nickname,
    gsiPartitionKey: "ALL",
    filterKey: field.includes("score_") ? "score" : field.includes("point_") ? "point" : field,

    branch: branch
  };

  console.log(logItem)
  const putCommand = new PutCommand({
    TableName: "log",
    Item: logItem,
  });

  try {
    await docClient.send(putCommand);
    console.log(`로그 생성 완료: ${field} 변경`);
  } catch (error) {
    console.error("로그 생성 중 오류 발생:", error);
  }
};

export const getUserLogs = async (userId: string, limit: number = 10) => {
  const command = new QueryCommand({
    TableName: "log",
    IndexName: "userId-timestamp-index",
    KeyConditionExpression: "userId = :userId",
    ExpressionAttributeValues: {
      ":userId": userId,
    },
    ScanIndexForward: false, // 최신 항목부터 가져오기 위해 역순으로 정렬
    // Limit: limit,
  });

  
  try {
    const response = await docClient.send(command);
    return response.Items;
  } catch (error) {
    console.error("로그 조회 중 오류 발생:", error);
    throw error;
  }
};


export const getUserRankLogs = async (userId: string, limit: number = 30) => {
  let items: any[] = [];
  let lastEvaluatedKey: any = undefined;

  // FilterExpression 생성
  const filterExpressions = branchOptions.map((branch, index) => 
    `field = :fieldValue${index}`
  );
  const filterExpression = filterExpressions.join(" OR ");

  // ExpressionAttributeValues 생성
  const expressionAttributeValues: { [key: string]: string } = {
    ":userId": userId,
  };
  branchOptions.forEach((branch, index) => {
    expressionAttributeValues[`:fieldValue${index}`] = `score_${branch.label}`;
  });

  do {
    const command = new QueryCommand({
      TableName: "log",
      IndexName: "userId-timestamp-index",
      KeyConditionExpression: "userId = :userId",
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ScanIndexForward: false,
      // Limit: 100,  // 각 쿼리에서 가져올 최대 항목 수
      ExclusiveStartKey: lastEvaluatedKey
    });

    try {
      const response = await docClient.send(command);
      
      if (response.Items) {
        items = items.concat(response.Items);
      }
      
      lastEvaluatedKey = response.LastEvaluatedKey;

    } catch (error) {
      console.error("로그 조회 중 오류 발생:", error);
      throw error;
    }
  } while (lastEvaluatedKey && items.length < limit);

  // limit개의 항목만 반환
  return items.slice(0, limit);
};

export const createBranchLog = async(userInfo: any, fromUsername: string) => {
  const branchLog = {
    id: uuid(),
    prevBranch: userInfo.branch,
    targetBranch: userInfo.branchRequest,
    userId: userInfo.id,
    nickname: userInfo.nickname,
    username: userInfo.username,
    timestamp: getKRCurrent(),
    field: "branch",
    gsiPartitionKey: "ALL",
    filterKey: "branch",
    branch: userInfo.branchRequest,
    fromUsername: fromUsername
  }

  const putCommand = new PutCommand({
    TableName: "log",
    Item: branchLog,
  });

  try {
    await docClient.send(putCommand);
    console.log(`지점 로그 생성 완료: `);
  } catch (error) {
    console.error("로그 생성 중 오류 발생:", error);
  }
}

export const getBranchLogs = async () => {
  const command = new QueryCommand({
    TableName: "branch-log",
    IndexName: "gsiPartitionKey-timestamp-index",
    KeyConditionExpression: "gsiPartitionKey = :gsi",
    ExpressionAttributeValues: {
      ":gsi": "ALL",
    },
    ScanIndexForward: false, // 최신 항목부터 가져오기 위해 역순으로 정렬

  });

  
  try {
    const response = await docClient.send(command);
    return response.Items;
  } catch (error) {
    console.error("로그 조회 중 오류 발생:", error);
    throw error;
  }
};

export const getUserBranchLogs = async (userId: string) => {
  const command = new QueryCommand({
    TableName: "branch-log",
    IndexName: "userId-timestamp-index",
    KeyConditionExpression: "userId = :userId",
    ExpressionAttributeValues: {
      ":userId": userId,
    },
    ScanIndexForward: false, // 최신 항목부터 가져오기 위해 역순으로 정렬

  });

  
  try {
    const response = await docClient.send(command);
    return response.Items;
  } catch (error) {
    console.error("로그 조회 중 오류 발생:", error);
    throw error;
  }
};

export const deleteUserLogs = async (userId: string) => {
  let totalDeletedItems = 0;
  let lastEvaluatedKey: any = null;  // null로 초기화

  do {
    
    const queryCommand = new QueryCommand({
      TableName: "log",
      IndexName: "userId-timestamp-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
      ...(lastEvaluatedKey && { ExclusiveStartKey: lastEvaluatedKey }),  // 조건부로 ExclusiveStartKey 추가
    });

    
    
    try {
      const response = await docClient.send(queryCommand);
      console.log(response);
      const items = response.Items || [];
      
      // 조회된 항목들을 삭제
      for (const item of items) {
        const deleteCommand = new DeleteCommand({
          TableName: "log",
          Key: {
            id: item.id,
            // userId: item.userId,
          },
        });

        await docClient.send(deleteCommand);
      }

      totalDeletedItems += items.length;
      console.log(`${items.length}개의 로그 항목 삭제 완료`);

      lastEvaluatedKey = response.LastEvaluatedKey || null;  // null로 설정

    } catch (error) {
      console.error("로그 삭제 중 오류 발생:", error);
      throw error;
    }
  } while (lastEvaluatedKey !== null);  // null이 아닐 때 계속

  console.log(`총 ${totalDeletedItems}개의 로그 항목 삭제 완료`);
};


export const getAllLogs = async (limit: number = 20, lastEvaluatedKey?: any) => {
  const command = new QueryCommand({
    TableName: "log",
    IndexName: "gsiPartitionKey-timestamp-index",
    KeyConditionExpression: "gsiPartitionKey = :gsi",
    ExpressionAttributeValues: {
      ":gsi": "ALL",
    },
    ScanIndexForward: false, // Get the latest items first
    Limit: limit,
    ...(lastEvaluatedKey && { ExclusiveStartKey: lastEvaluatedKey }),
  });

  try {
    const response = await docClient.send(command);
    return {
      items: response.Items,
      lastEvaluatedKey: response.LastEvaluatedKey,
    };
  } catch (error) {
    console.error("Error fetching all logs:", error);
    throw error;
  }
};

export const createReservationDeleteLog = async (
  userId: string,
  branch: string,
  username: string,
  nickname: string,
  rallyName: string,
  
  fromUsername: string,
) => {
  // 현재 시간을 한국 시간대(KST)로 변환
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const korNow = new Date(utc + koreaTimeDiff);



  const logItem = {
    id: uuid(),
    timestamp: getKRCurrent(),
    field: "reservationDelete",
    
    userId: userId,
    branch: branch,
    username: username,
    nickname: nickname,
    rallyName: rallyName,

    fromUsername: fromUsername,
    gsiPartitionKey: "ALL",
    filterKey: "reservation"
  };

  const putCommand = new PutCommand({
    TableName: "log",
    Item: logItem,
  });

  try {
    await docClient.send(putCommand);
    console.log(`대회 취소 로그 생성 완료 ${userId}`);
  } catch (error) {
    console.error("로그 생성 중 오류 발생:", error);
  }
};

export const createUserDeleteLog = async (
  userId: string,
  branch: string,
  username: string,
  nickname: string,  
  fromUsername: string,
) => {
  // 현재 시간을 한국 시간대(KST)로 변환
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const koreaTimeDiff = 9 * 60 * 60 * 1000;
  const korNow = new Date(utc + koreaTimeDiff);



  const logItem = {
    id: uuid(),
    timestamp: getKRCurrent(),
    field: "userDelete",
    
    userId: userId,
    branch: branch,
    username: username,
    nickname: nickname,
    fromUsername: fromUsername,
    gsiPartitionKey: "ALL",
    filterKey: "delete"
  };

  const putCommand = new PutCommand({
    TableName: "log",
    Item: logItem,
  });

  try {
    await docClient.send(putCommand);
    console.log(`대회 취소 로그 생성 완료 ${userId}`);
  } catch (error) {
    console.error("로그 생성 중 오류 발생:", error);
  }
};


export const getFieldFilteredLogs = async (
  filterKey: string,
  limit: number = 20,
  lastEvaluatedKey?: Record<string, any>
) => {
  console.log(filterKey);
  const queryCommandInput:any = {
    TableName: "log",
    IndexName: "filterKey-timestamp-index",
    KeyConditionExpression: "filterKey = :filterKey",
    ExpressionAttributeValues: {
      ":filterKey": filterKey,
    },
    ScanIndexForward: false, // Sort in descending order to get the latest items first
    Limit: limit,
  }
  

  if (lastEvaluatedKey) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const command = new QueryCommand(queryCommandInput);

  try {
    const response = await docClient.send(command);
    return {
      items: response.Items,
      lastEvaluatedKey: response.LastEvaluatedKey,
    };
  } catch (error) {
    console.error("Error occurred while fetching logs:", error);
    throw error;
  }
};

export const getBranchFilteredLogs = async (
  branch: string,
  limit: number = 20,
  lastEvaluatedKey?: Record<string, any>
) => {

  const queryCommandInput:any = {
    TableName: "log",
    IndexName: "branch-timestamp-index",
    KeyConditionExpression: "branch = :branch",
    ExpressionAttributeValues: {
      ":branch": branch,
    },
    ScanIndexForward: false, // Sort in descending order to get the latest items first
    Limit: limit,
  }
  

  if (lastEvaluatedKey) {
    queryCommandInput.ExclusiveStartKey = lastEvaluatedKey;
  }

  const command = new QueryCommand(queryCommandInput);

  try {
    const response = await docClient.send(command);
    return {
      items: response.Items,
      lastEvaluatedKey: response.LastEvaluatedKey,
    };
  } catch (error) {
    console.error("Error occurred while fetching logs:", error);
    throw error;
  }
};