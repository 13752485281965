// src/utils/news.ts

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, PutCommand, ScanCommand, UpdateCommand, DeleteCommand, GetCommand, QueryCommand } from "@aws-sdk/lib-dynamodb";
import uuid from "react-uuid";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const docClient = DynamoDBDocumentClient.from(client);

const NEWS_TABLE_NAME = "news";  // DynamoDB의 테이블 이름

interface News {
  id: string;
  title: string;
  content: string;
  lastModifiedAt: string;
  mainImage?: string;
  images ?: any[];
}

// 뉴스 생성
export const createNews = async (news: Omit<News, 'id'>) => {
  const newsId = uuid();
  const params = {
    TableName: NEWS_TABLE_NAME,
    Item: {
      id: newsId,
      title: news.title,
      content: news.content,
      gsiPartitionKey: "ALL",
      lastModifiedAt: new Date().toISOString(),
      mainImage: news.mainImage || "",
      images: news.images || []
    },
  };

  try {
    await docClient.send(new PutCommand(params));
    return { success: true, id: newsId };
  } catch (error) {
    console.error("Error creating news:", error);
    return { success: false, error };
  }
};

// 뉴스 읽기 (전체 뉴스 조회)
export const readNews = async () => {
  const params = {
    TableName: NEWS_TABLE_NAME,
    IndexName: "gsiPartitionKey-lastModifiedAt-index",
    KeyConditionExpression: "gsiPartitionKey = :pkValue",
    ExpressionAttributeValues: {
      ":pkValue": "ALL", // Assuming all news items have this partition key in GSI
    },
    ScanIndexForward: false, // This will sort in descending order (newest first)
  };

  try {
    const data = await docClient.send(new QueryCommand(params));
    const news = data.Items as News[];

    return { success: true, news };
  } catch (error) {
    console.error("Error reading news:", error);
    return { success: false, error };
  }
};

// 특정 ID의 뉴스 조회
export const getNewsById = async (id: string) => {
  const params = {
    TableName: NEWS_TABLE_NAME,
    Key: { id },
  };

  try {
    const { Item } = await docClient.send(new GetCommand(params));
    if (Item) {
      return { success: true, news: Item as News };
    } else {
      return { success: false, error: "News not found" };
    }
  } catch (error) {
    console.error("Error getting news by id:", error);
    return { success: false, error };
  }
};


// 뉴스 업데이트
export const updateNews = async (news: News) => {
  const params = {
    TableName: NEWS_TABLE_NAME,
    Key: { id: news.id },
    UpdateExpression: "SET title = :title, content = :content, lastModifiedAt = :lastModifiedAt, mainImage = :mainImage, images = :images",
    ExpressionAttributeValues: {
      ":title": news.title,
      ":content": news.content,
      ":mainImage": news.mainImage || "",
      ":lastModifiedAt": new Date().toISOString(),
      ":images" : news.images || []
    },
  };

  try {
    await docClient.send(new UpdateCommand(params));
    return { success: true };
  } catch (error) {
    console.error("Error updating news:", error);
    return { success: false, error };
  }
};

// 뉴스 삭제
export const deleteNews = async (id: string) => {
  const params = {
    TableName: NEWS_TABLE_NAME,
    Key: { id },
  };

  try {
    await docClient.send(new DeleteCommand(params));
    return { success: true };
  } catch (error) {
    console.error("Error deleting news:", error);
    return { success: false, error };
  }
};
