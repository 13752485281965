import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  PutCommand,
  DeleteCommand,
  ScanCommand,
} from "@aws-sdk/lib-dynamodb";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});
const docClient = DynamoDBDocumentClient.from(client);

export const fetchLocations = async () => {
  try {
    const result = await docClient.send(
      new ScanCommand({ TableName: "location" })
    );
    return { success: true, locations: result.Items };
  } catch (error) {
    console.error("Error fetching locations:", error);
    return { success: false, error: "지점 정보를 불러오는 데 실패했습니다." };
  }
};

export const addOrUpdateLocation = async (location: any) => {
  try {
    await docClient.send(
      new PutCommand({
        TableName: "location",
        Item: location,
      })
    );
    return { success: true };
  } catch (error) {
    console.error("Error saving location:", error);
    return { success: false, error: "지점 정보 저장에 실패했습니다." };
  }
};

export const deleteLocation = async (id: string) => {
  try {
    await docClient.send(
      new DeleteCommand({
        TableName: "location",
        Key: { id },
      })
    );
    return { success: true };
  } catch (error) {
    console.error("Error deleting location:", error);
    return { success: false, error: "지점 정보 삭제에 실패했습니다." };
  }
};
