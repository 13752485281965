import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Ranking from "./pages/Ranking";
import Profile from "./pages/Profile";
import News from "./pages/News";
import Location from "./pages/Location";
import styled from "styled-components";
import { RecoilRoot, useRecoilState } from "recoil";
import { ConfigProvider, theme } from "antd";
import NavigationBar from "./components/NavigationBar";
import Admin from "./pages/Admin";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/route/PrivateRoute";
import { userInfoState } from "./atom/userInfo";
import { getUserById } from "./utils/user";
import Index from "./pages/Index";
import NewsDetail from "./pages/NewsDetail";
import Data from "./pages/Data";
import DataDetail from "./pages/DataDetail";
import Footer from "./components/Footer";
import Rally from "./pages/Rally";
import RallyDetail from "./pages/RallyDetail";
import UserEditPopup from "./components/popup/UserEditPopup";
import UserLogPopup from "./components/popup/UserLogPopup";
import UserRankPopup from "./components/popup/UserRankPopup";
import FindPassword from "./pages/FindPassword";
import ResetPassword from "./pages/ResetPassword";

const Wrapper = styled.div`
  background: #000;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  overflow-x: hidden;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
`;

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/find-password" element={<FindPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route
            path="/*"
            element={
              <>
                <NavigationBar
                  current={window.location.pathname.replace("/", "") || "home"}
                />
                <Routes>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/ranking" element={<Ranking />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/location" element={<Location />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/news/:id" element={<NewsDetail />} />
                  <Route path="/data" element={<Data />} />
                  <Route path="/data/:id" element={<DataDetail />} />

                  <Route path="/rally" element={<Rally />} />
                  <Route path="/rally/:id" element={<RallyDetail />} />
                  <Route path="*" element={<NotFound />} />

                  <Route path="/user-edit" element={<UserEditPopup />} />
                  <Route path="/user-log" element={<UserLogPopup />} />
                  <Route path="/user-rank" element={<UserRankPopup />} />
                  
                </Routes>
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <Wrapper>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "#ffd700",
            colorBgContainer: "#1a1a2e",
            colorText: "#ffffff",
            fontFamily: "NotoSans-KR",
          },
        }}
      >
        <RecoilRoot>
          <BrowserRouter>
            <Content>
              <Main>
                <AppRoutes />
              </Main>
              <Footer />
            </Content>
          </BrowserRouter>
        </RecoilRoot>
      </ConfigProvider>
    </Wrapper>
  );
}

export default App;
