import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Card, Row, Col, Divider, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Header from "../components/Header";
import { readNews } from "../utils/news";

const { Title } = Typography;
const { Meta } = Card;

const PageContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0px auto;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .ant-card-body {
    padding: 0;
  }
`;

const Logo = styled.img`
  width: 180px;
  margin: 0 auto 0px;
`;

const Banner = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 8px;
`;

const NewsSection = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;



export default function Home() {
  const [allNews, setAllNews] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const navigate = useNavigate();

  const fetchNews = async () => {
    setLoading(true);
    const result = await readNews();
    if (result.success) {
      if (!result.news) return;
      setAllNews(result.news);
    } else {
      console.error("Failed to fetch news:", result.error);
      // You might want to show an error message to the user here
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleNewsClick = (id: string) => {
    navigate(`/news/${id}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

    // Get current news items
    const indexOfLastNews = currentPage * pageSize;
    const indexOfFirstNews = indexOfLastNews - pageSize;
    const currentNews = allNews.slice(indexOfFirstNews, indexOfLastNews);

  return (
    <PageContainer>
      <Logo src="/logos/black2.jpeg" alt="Logo" />

      
      <NewsSection>
      <Title level={3} style={{ margin: "0px 0 30px"}}>대회소식</Title>
        <div style={{ display: "grid", gap: 20, gridTemplateColumns: "1fr 1fr" }}>
          
          {currentNews.map((item) => (
            <Card
              hoverable

              cover={
                <img
                  alt={item.title}
                  src={item.mainImage}
                  style={{ height: 200, objectFit: "cover", borderRadius: 8 }}
                />
              }
              onClick={() => handleNewsClick(item.id)}
              style={{ border: "none", background: "transparent" }}
            >
              {/* <Meta
                title={item.title}
                description={`${formatDate(item.lastModifiedAt)}`}
              /> */}
            </Card>
          ))}
        </div>
        <PaginationWrapper>
          <Pagination
            current={currentPage}
            total={allNews.length}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </PaginationWrapper>
      </NewsSection>
      <Divider />
      <Banner
        src={`${process.env.REACT_APP_S3_STORAGE}/banner-image?t=${new Date().toISOString()}`}
        alt="Banner"
      />
    </PageContainer>
  );
}
