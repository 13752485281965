// src/utils/data.ts

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  PutCommand,
  ScanCommand,
  UpdateCommand,
  DeleteCommand,
  GetCommand,
} from "@aws-sdk/lib-dynamodb";
import uuid from "react-uuid";

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const docClient = DynamoDBDocumentClient.from(client);

const DATA_TABLE_NAME = "data"; // DynamoDB의 테이블 이름

interface Data {
  id: string;
  title: string;
  content: string;
  lastModifiedAt: string;
  files?: any[];
}

// 데이터 생성
export const createData = async (data: Omit<Data, "id">) => {
  const dataId = uuid();
  const params = {
    TableName: DATA_TABLE_NAME,
    Item: {
      id: dataId,
      title: data.title,
      content: data.content,
      gsiPartitionKey: "ALL",
      files: data.files || [],
      lastModifiedAt: new Date().toISOString(),
    },
  };

  try {
    await docClient.send(new PutCommand(params));
    return { success: true, id: dataId };
  } catch (error) {
    console.error("Error creating data:", error);
    return { success: false, error };
  }
};

// 데이터 읽기 (전체 데이터 조회)
export const readData = async () => {
  const params = {
    TableName: DATA_TABLE_NAME,
  };

  try {
    const result = await docClient.send(new ScanCommand(params));
    const data = result.Items as Data[];
    // Sort items by timestamp, handling cases where timestamp might not exist
    const sortedItems = data?.sort((a, b) => {
      if (!a.lastModifiedAt && !b.lastModifiedAt) return 0;
      if (!a.lastModifiedAt) return 1;
      if (!b.lastModifiedAt) return -1;
      return (
        new Date(b.lastModifiedAt).getTime() -
        new Date(a.lastModifiedAt).getTime()
      );
    });
    return { success: true, data: sortedItems };
  } catch (error) {
    console.error("Error reading data:", error);
    return { success: false, error };
  }
};

// 특정 ID의 데이터 조회
export const getDataById = async (id: string) => {
  const params = {
    TableName: DATA_TABLE_NAME,
    Key: { id },
  };

  try {
    const { Item } = await docClient.send(new GetCommand(params));
    if (Item) {
      return { success: true, data: Item as Data };
    } else {
      return { success: false, error: "Data not found" };
    }
  } catch (error) {
    console.error("Error getting data by id:", error);
    return { success: false, error };
  }
};

// 데이터 업데이트
export const updateData = async (data: Data) => {
  const params = {
    TableName: DATA_TABLE_NAME,
    Key: { id: data.id },
    UpdateExpression:
      "SET title = :title, content = :content, lastModifiedAt = :lastModifiedAt, files = :files",
    ExpressionAttributeValues: {
      ":title": data.title,
      ":content": data.content,
      ":lastModifiedAt": new Date().toISOString(),
      ":files": data.files || [],
    },
  };

  try {
    await docClient.send(new UpdateCommand(params));
    return { success: true };
  } catch (error) {
    console.error("Error updating data:", error);
    return { success: false, error };
  }
};

// 데이터 삭제
export const deleteData = async (id: string) => {
  const params = {
    TableName: DATA_TABLE_NAME,
    Key: { id },
  };

  try {
    await docClient.send(new DeleteCommand(params));
    return { success: true };
  } catch (error) {
    console.error("Error deleting data:", error);
    return { success: false, error };
  }
};
