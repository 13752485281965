const ranks = [
  { name: "유티지", minScore: 5 },
  { name: "미들포지션", minScore: 20 },
  { name: "하이잭", minScore: 50 },
  { name: "컷오프", minScore: 100 },
  { name: "버튼", minScore: 200 }
];

export const getRank = (score: number): string => {
  // 점수가 5 미만이면 null 반환 (랭킹 없음)
  if (score < 5) {
    return "";
  }

  // 점수에 해당하는 가장 높은 랭크를 찾음
  for (let i = ranks.length - 1; i >= 0; i--) {
    if (score >= ranks[i].minScore) {
      return ranks[i].name;
    }
  }

  // 이 부분은 실행되지 않겠지만, TypeScript의 타입 안정성을 위해 추가
  return "";
};