import { atom, selector } from "recoil";
import { localStorageEffect } from "./effect";
import { updateUser } from "../utils/user";

export const userInfoState = atom<any>({
  key: "userInfoBaseState",
  default: {},
  effects: [localStorageEffect<any>("userInfo_doubleup")],
});

// export const userInfoState = selector<any>({
//   key: "userInfoState",
//   get: ({ get }) => get(userInfoBaseState),
//   set: ({ set }, newValue) => {
//     set(userInfoBaseState, newValue);
//     updateUser(newValue).then((result) => {
//       if (!result.success) {
//         console.error("사용자 정보 업데이트 실패:", result.error);
//       }
//     });
//   },
// });