import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Modal,
  Form,
  Input,
  Upload,
  Space,
} from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { readNews, createNews, updateNews, deleteNews } from "../../utils/news";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDate } from "../../utils/date";
import { uploadToS3 } from "../../utils/s3";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

interface News {
  id: string;
  title: string;
  content: string;
  mainImage: string;
  mainImageName: string;
  images: string[];
  imageNames: string[];
}

export default function NewsAdmin() {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingNews, setEditingNews] = useState<News | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editorContent, setEditorContent] = useState("");
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [mainImageName, setMainImageName] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [additionalImageNames, setAdditionalImageNames] = useState<string[]>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setLoading(true);
    try {
      const result: any = await readNews();
      if (result.success) {
        setNews(result.news);
      } else {
        message.error("대회 소식를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      message.error("대회 소식를 불러오는데 실패했습니다.");
    }
    setLoading(false);
  };

  const handleEdit = (news: News) => {
    setEditingNews(news);
    form.setFieldsValue(news);
    setEditorContent(news.content);
    setMainImage(news.mainImage || null);
    setMainImageName(news.mainImageName || null);
    setAdditionalImages(news.images || []);
    setAdditionalImageNames(news.imageNames || []);
    setIsModalVisible(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await deleteNews(id);
      if (result.success) {
        message.success("대회 소식가 삭제되었습니다.");
        setNews(news.filter((item) => item.id !== id));
      } else {
        message.error("대회 소식 삭제에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error deleting news:", error);
      message.error("대회 소식 삭제 중 오류가 발생했습니다.");
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const newsData = {
        ...editingNews,
        ...values,
        content: editorContent,
        mainImage,
        mainImageName,
        images: additionalImages,
        imageNames: additionalImageNames,
      };
      let result;
      if (editingNews) {
        result = await updateNews(newsData);
      } else {
        result = await createNews(newsData);
      }

      if (result.success) {
        message.success(
          editingNews
            ? "대회 소식가 업데이트되었습니다."
            : "대회 소식가 추가되었습니다."
        );
        setIsModalVisible(false);
        fetchNews();
      } else {
        message.error(
          editingNews
            ? "대회 소식 업데이트에 실패했습니다."
            : "대회 소식 추가에 실패했습니다."
        );
      }
    } catch (error) {
      console.error("Error saving news:", error);
      message.error("대회 소식 저장 중 오류가 발생했습니다.");
    }
  };

  const handleAdd = () => {
    setEditingNews(null);
    form.resetFields();
    setEditorContent("");
    setMainImage(null);
    setMainImageName(null);
    setAdditionalImages([]);
    setAdditionalImageNames([]);
    setIsModalVisible(true);
  };

  const handleMainImageUpload = async (file: any) => {
    try {
      const imageName = await uploadToS3(file);
      const imageUrl = `${process.env.REACT_APP_S3_STORAGE}/${imageName}`;
      setMainImage(imageUrl);
      setMainImageName(imageName);
      message.success("메인 이미지가 업로드되었습니다.");
    } catch (error) {
      console.error("Failed to upload image:", error);
      message.error("이미지 업로드에 실패했습니다.");
    }
  };

  const handleAdditionalImageUpload = async (file: any) => {
    try {
      const imageName = await uploadToS3(file);
      const imageUrl = `${process.env.REACT_APP_S3_STORAGE}/${imageName}`;
      setAdditionalImages((prevImages) => [...prevImages, imageUrl]);
      setAdditionalImageNames((prevNames) => [...prevNames, imageName]);
      message.success("추가 이미지가 업로드되었습니다.");
    } catch (error) {
      console.error("Failed to upload image:", error);
      message.error("이미지 업로드에 실패했습니다.");
    }
  };

  const handleRemoveAdditionalImage = (index: number) => {
    setAdditionalImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setAdditionalImageNames((prevNames) => prevNames.filter((_, i) => i !== index));
  };

  const columns = [
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "마지막으로 수정된 날짜",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
      render: (v: any) => formatDate(v),
    },
    {
      title: "작업",
      key: "action",
      render: (_: any, record: News) => (
        <Space.Compact>
          <Button
            onClick={() => handleEdit(record)}
            size="small"
            icon={<EditOutlined />}
          >
            수정
          </Button>
          <Button
            danger
            size="small"
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}
          >
            삭제
          </Button>
        </Space.Compact>
      ),
    },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <Wrapper>
      <Button
        icon={<PlusOutlined />}
        onClick={handleAdd}
        style={{ marginBottom: 20 }}
      >
        대회 소식 추가
      </Button>
      <Table
        size={isMobile ? "small" : "middle"}
        dataSource={news}
        columns={columns}
        rowKey="id"
        loading={loading}
        scroll={{ x: 500 }}
      />

      <Modal
        title={editingNews ? "대회 소식 편집" : "대회 소식 추가"}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        centered
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: "제목을 입력하세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="메인 이미지">
            <Upload
              accept="image/*"
              beforeUpload={(file) => {
                handleMainImageUpload(file);
                return false;
              }}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>메인 이미지 업로드</Button>
            </Upload>
            {mainImage && (
              <img
                src={mainImage}
                alt="Main"
                style={{ maxWidth: "100%", marginTop: 10, borderRadius: 8 }}
              />
            )}
          </Form.Item>
          <Form.Item label="추가 이미지">
            <Upload
              accept="image/*"
              beforeUpload={(file) => {
                handleAdditionalImageUpload(file);
                return false;
              }}
              fileList={[]}
            >
              <Button icon={<UploadOutlined />}>추가 이미지 업로드</Button>
            </Upload>
            <div style={{ marginTop: 16 }}>
              {additionalImages.map((image, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  <img
                    src={image}
                    alt={`Additional ${index + 1}`}
                    style={{ width: 100, height: 100, objectFit: 'cover', marginRight: 8 }}
                  />
                  <Button onClick={() => handleRemoveAdditionalImage(index)} danger>
                    삭제
                  </Button>
                </div>
              ))}
            </div>
          </Form.Item>
          <Form.Item label="내용" name="content">
            <ReactQuill
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              theme="snow"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Wrapper>
  );
}