import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Space, Spin, Upload, Button } from "antd";
import styled from "styled-components";
import { getDataById } from "../utils/data";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { AiOutlineMenu } from "react-icons/ai";

const { Title, Text } = Typography;

const lambdaClient = new LambdaClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const Content = styled.div`
  color: #fff;
  img {
    width: 100%;
    border-radius: 8px;
  }
`;

interface Data {
  id: string;
  title: string;
  content: string;
  lastModifiedAt: string;
  files?: any[];
}

export default function DataDetail() {
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchDataDetail(id);
    }
  }, [id]);

  const fetchDataDetail = async (dataId: string) => {
    setLoading(true);
    const result = await getDataById(dataId);
    if (result.success) {
      if (!result.data) return;
      setData(result.data);
    } else {
      console.error("Failed to fetch data detail:", result.error);
      // You might want to show an error message to the user here
    }
    setLoading(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleDownload = async (file: any) => {
    try {
      const command = new InvokeCommand({
        FunctionName: "file-download", // Lambda 함수 이름을 여기에 입력하세요
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({ key: file.key }),
      });

      const response = await lambdaClient.send(command);

      if (response.StatusCode === 200 && response.Payload) {
        const textDecoder = new TextDecoder("utf-8");
        const decodedPayload = textDecoder.decode(response.Payload);
        const result = JSON.parse(decodedPayload);

        if (result.statusCode === 200 && result.body) {
          const { url } = JSON.parse(result.body);

          // 원하는 파일 이름 설정
          const fileName = file.name || "downloaded_file";

          // a 태그를 사용하여 파일 다운로드
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          throw new Error("Failed to get download URL");
        }
      } else {
        throw new Error("Lambda invocation failed");
      }
    } catch (error) {
      console.error("Error initiating file download:", error);
      // 에러 처리 (예: 사용자에게 에러 메시지 표시)
    }
  };

  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }

  if (!data) {
    return <Title level={3}>자료를 찾을 수 없습니다.</Title>;
  }

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        padding: "24px",
        width: "100%",
        maxWidth: 1200,
        boxSizing: "border-box",
        margin: "0 auto",
        gap: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          {data.title}
        </Title>
        <Button
          style={{ padding: 3, marginRight: 20 }}
          type="text"
          onClick={() => navigate("/data")}
        >
          <AiOutlineMenu size={30} />
        </Button>
      </div>

      <Text type="secondary">{formatDate(data.lastModifiedAt)}</Text>
      <Content dangerouslySetInnerHTML={{ __html: data.content }} />
      {data.files && data.files.length > 0 && (
        <Upload
          fileList={data.files}
          onPreview={handleDownload}
          listType="text"
          showUploadList={{
            showRemoveIcon: false,
            showDownloadIcon: true,
          }}
        />
      )}
    </Space>
  );
}
