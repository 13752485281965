import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  color: #a0a0a0;
  width: 100%;
  padding: 20px 25px;
  font-size: 0.85em;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

`;

const Container = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 15px;
`;

const Column = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 15px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 1em;
  margin-bottom: 10px;
  color: #c0c0c0;
`;

const Info = styled.p`
  margin: 3px 0;
  font-size: 0.9em;
  line-height: 1.4;
`;

const ContactInfo = styled(Info)`
  display: flex;
  align-items: center;
  
  &:before {
    content: '•';
    margin-right: 5px;
    color: #c0c0c0;
  }
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.4);
`;

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <Column>
          <Title>사업자 정보</Title>
          <Info>더블업 홀덤펍 수영본점</Info>
          <Info>대표자: 정의택</Info>
          <Info>사업자등록번호: 101-27-30181</Info>
        </Column>
        <Column>
          <Title>주소</Title>
          <Info>부산광역시 수영구 수영로705번길 5, 4층</Info>
          <Info>(수영동, 엠에스빌딩)</Info>
        </Column>
        <Column>
          <Title>담당자</Title>
          <ContactInfo>서문교 : 010-2322-8151</ContactInfo>
          {/* <ContactInfo>정현진 : 010-5634-5728</ContactInfo> */}
        </Column>
      </Container>
      <Copyright>© 2024 Double Up. All rights reserved.</Copyright>
    </Wrapper>
  )
}