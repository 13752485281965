import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Typography, Card, Space } from "antd";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "../utils/user";
import { Lock } from "lucide-react";

const { Title, Text } = Typography;

const ENCRYPTION_KEY = "ckdhkdwnsckdhkdwnsckdhkdwnsckdhk";

const decrypt = (ciphertext: any) => {
  try {
    const [ivHex, encryptedHex] = ciphertext.split(":");
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);

    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: encrypted,
      iv: iv,
      key: key,
    });

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: iv });
    return CryptoJS.enc.Utf8.stringify(decrypted);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

const containerStyle = {
  maxWidth: '400px',
  margin: '32px auto',
};

const cardStyle = {
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
};

const iconStyle = {
  fontSize: '48px',
  color: '#1890ff',
  marginBottom: '16px',
};

export default function ResetPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidToken, setIsValidToken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      const decryptedData = decrypt(token);
      if (decryptedData) {
        try {
          const { email: decryptedEmail, expirationDate } = JSON.parse(decryptedData);

          if (new Date(expirationDate) > new Date()) {
            setEmail(decryptedEmail);
            setIsValidToken(true);
          } else {
            message.error("링크가 만료되었습니다. 새로운 비밀번호 재설정 링크를 요청해주세요.");
          }
        } catch (error) {
          console.error("Token data parsing error:", error);
          message.error("유효하지 않은 링크입니다.");
        }
      } else {
        message.error("유효하지 않은 링크입니다.");
      }
    } else {
      message.error("토큰이 없습니다. 올바른 링크를 사용해주세요.");
    }
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await updatePassword(email, values.password);
      message.success("비밀번호가 성공적으로 재설정되었습니다.");
      navigate("/login");
    } catch (error) {
      console.error("비밀번호 재설정 실패:", error);
      message.error("비밀번호 재설정에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setLoading(false);
    }
  };

  if (!isValidToken) {
    return (
      <div style={containerStyle}>

          <Title level={3}>유효하지 않은 링크</Title>
          <Text>새로운 비밀번호 재설정 링크를 요청해주세요.</Text>

      </div>
    );
  }

  return (
    <div style={containerStyle}>

        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            {/* <Lock style={iconStyle} /> */}
            <Title level={2}>비밀번호 재설정</Title>
            <Text>이메일: {email}</Text>
          </div>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "새 비밀번호를 입력해주세요" },
                { min: 8, message: "비밀번호는 8자 이상이어야 합니다" },
              ]}
            >
              <Input.Password placeholder="새 비밀번호" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "비밀번호를 다시 입력해주세요" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("비밀번호가 일치하지 않습니다"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="새 비밀번호 확인" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                비밀번호 재설정
              </Button>
            </Form.Item>
          </Form>
        </Space>

    </div>
  );
}