import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Space,
  message,
  Upload,
  Tabs,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { uploadToS3 } from "../../utils/s3";
import {
  fetchLocations,
  addOrUpdateLocation,
  deleteLocation,
} from "../../utils/location";
import { RcFile } from "antd/lib/upload";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const { TabPane } = Tabs;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 20px;
`;

interface Location {
  id: string;
  name: string;
  address: string;
  phone: string;
  kakaoLink: string;
  images?: string[];
  mainImage?: string;
  lat?: number;
  lng?: number;
  createdAt?: any;
}
const LocationAdmin: React.FC = () => {
  const [locations, setLocations] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editingLocation, setEditingLocation] = useState<Location | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [mainImage, setMainImage] = useState<string>("");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [formData, setFormData] = useState<Omit<Location, "id">>({
    name: "",
    address: "",
    phone: "",
    kakaoLink: "",
    lat: 37.566826,
    lng: 126.9786567,
  });

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    const result = await fetchLocations();
    if (result.success) {
      const sortedLocations = (result.locations || []).sort(
        (a: any, b: any) => a.createdAt - b.createdAt
      );
      console.log(sortedLocations);
      setLocations(sortedLocations);
    } else {
      message.error(result.error);
    }
  };

  const showModal = (record: Location | null = null) => {
    setEditingLocation(record);
    if (record) {
      setFormData({
        name: record.name,
        address: record.address,
        phone: record.phone,
        kakaoLink: record.kakaoLink,
        lat: record.lat || 37.566826,
        lng: record.lng || 126.9786567,
      });
      setUploadedImages(record.images || []);
      setMainImage(record.mainImage || "");
    } else {
      setFormData({
        name: "",
        address: "",
        phone: "",
        kakaoLink: "",
        lat: 37.566826,
        lng: 126.9786567,
      });
      setUploadedImages([]);
      setMainImage("");
    }
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (
      !formData.name ||
      !formData.address ||
      !formData.phone ||
      !formData.kakaoLink ||
      !formData.lat ||
      !formData.lng ||
      !mainImage
    ) {
      message.error("모든 필드를 입력해주세요.");
      return;
    }

    const location: Location = {
      ...formData,
      id: editingLocation ? editingLocation.id : Date.now().toString(),
      images: uploadedImages,
      mainImage: mainImage,
      createdAt: editingLocation
        ? editingLocation.createdAt || Date.now().toString()
        : Date.now().toString(),
    };

    const result = await addOrUpdateLocation(location);
    if (result.success) {
      message.success(
        `지점 정보가 ${editingLocation ? "수정" : "추가"}되었습니다.`
      );
      setIsModalVisible(false);
      loadLocations();
    } else {
      message.error(result.error);
    }
  };

  const handleDelete = async (id: string) => {
    const result = await deleteLocation(id);
    if (result.success) {
      message.success("지점 정보가 삭제되었습니다.");
      loadLocations();
    } else {
      message.error(result.error);
    }
  };

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const uu = await uploadToS3(file);
      onSuccess("Ok");
      setUploadedImages((prev) => [...prev, uu]);
    } catch (error) {
      console.error("Error uploading file:", error);
      onError({ error });
    }
  };

  const handleMainImageUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const uu = await uploadToS3(file);
      onSuccess("Ok");
      setMainImage(uu);
    } catch (error) {
      console.error("Error uploading main image:", error);
      onError({ error });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteImage = (indexToDelete: number) => {
    setUploadedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleDeleteMainImage = () => {
    setMainImage("");
  };

  const handleMapClick = (_t: kakao.maps.Map, mouseEvent: any) => {
    setFormData((prev) => ({
      ...prev,
      lat: mouseEvent.latLng.getLat(),
      lng: mouseEvent.latLng.getLng(),
    }));
  };

  const handleSearch = () => {
    const ps = new kakao.maps.services.Places();
    ps.keywordSearch(searchKeyword, (data, status, _pagination) => {
      if (status === kakao.maps.services.Status.OK) {
        const { y, x } = data[0];
        setFormData((prev) => ({
          ...prev,
          lat: parseFloat(y),
          lng: parseFloat(x),
        }));
      }
    });
  };

  const columns = [
    { title: "지점명", dataIndex: "name", key: "name" },
    { title: "주소", dataIndex: "address", key: "address" },
    {
      title: "작업",
      key: "action",
      render: (_: any, record: Location) => (
        <Space.Compact>
          <Button
            icon={<EditOutlined />}
            onClick={() => showModal(record)}
            size="small"
          >
            수정
          </Button>
          <Button
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            danger
          >
            삭제
          </Button>
        </Space.Compact>
      ),
    },
  ];

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        onClick={() => showModal()}
        style={{ marginBottom: "16px" }}
      >
        새 지점 추가
      </Button>
      <Table
        columns={columns}
        dataSource={locations}
        rowKey="id"
        size={isMobile ? "small" : "middle"}
      />
      <Modal
        title={editingLocation ? "지점 정보 수정" : "새 지점 추가"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        width="80%"
        style={{ maxWidth: "1000px" }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="지점 정보" key="1">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                name="name"
                placeholder="지점명"
                value={formData.name}
                onChange={handleInputChange}
              />
              <Input
                name="address"
                placeholder="주소"
                value={formData.address}
                onChange={handleInputChange}
              />
              <Input
                name="phone"
                placeholder="연락처"
                value={formData.phone}
                onChange={handleInputChange}
              />
              <Input
                name="kakaoLink"
                placeholder="카카오톡 링크"
                value={formData.kakaoLink}
                onChange={handleInputChange}
              />
            </Space>
          </TabPane>
          <TabPane tab="사진" key="2">
            <h4>메인 이미지</h4>
            <Upload
              customRequest={handleMainImageUpload}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} style={{ marginBottom: 20 }}>
                메인 이미지 업로드
              </Button>
            </Upload>
            {mainImage && (
              <ImageContainer>
                <StyledImage
                  src={`${process.env.REACT_APP_S3_STORAGE}/${mainImage}`}
                  alt="Main image"
                />
                <DeleteButton
                  icon={<CloseCircleOutlined />}
                  onClick={handleDeleteMainImage}
                  danger
                />
              </ImageContainer>
            )}
            <h4>추가 이미지</h4>
            <Upload
              customRequest={handleUpload}
              multiple={true}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>추가 이미지 업로드</Button>
            </Upload>
            <ImageGrid>
              {uploadedImages.map((uu, index) => (
                <ImageContainer key={index}>
                  <StyledImage
                    src={`${process.env.REACT_APP_S3_STORAGE}/${uu}`}
                    alt={`Uploaded image ${index + 1}`}
                  />
                  <DeleteButton
                    icon={<CloseCircleOutlined />}
                    onClick={() => handleDeleteImage(index)}
                    danger
                  />
                </ImageContainer>
              ))}
            </ImageGrid>
            {uploadedImages.length === 0 && (
              <div
                style={{
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#f0f0f0",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                추가 이미지 없음
              </div>
            )}
          </TabPane>
          <TabPane tab="지도" key="3">
            <Space
              direction="vertical"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <Input
                placeholder="위치 검색"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onPressEnter={handleSearch}
                suffix={
                  <SearchOutlined
                    onClick={handleSearch}
                    style={{ cursor: "pointer" }}
                  />
                }
              />
            </Space>
            <MapContainer>
              <Map
                center={{ lat: formData.lat!, lng: formData.lng! }}
                style={{ width: "100%", height: "100%", borderRadius: 8 }}
                onClick={handleMapClick}
              >
                <MapMarker
                  position={{ lat: formData.lat!, lng: formData.lng! }}
                />
              </Map>
            </MapContainer>
            <p>
              위도: {formData.lat}, 경도: {formData.lng}
            </p>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default LocationAdmin;
