import React, { useEffect, useState } from "react";
import { Typography, Space, Spin, Row, Col, Card, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { readNews } from "../utils/news";

const { Title } = Typography;
const { Meta } = Card;

interface News {
  id: string;
  title: string;
  lastModifiedAt: string;
  mainImage: string;
}

export default function News() {
  const [allNews, setAllNews] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchAllNews();
  }, []);

  const fetchAllNews = async () => {
    setLoading(true);
    const result = await readNews();
    if (result.success) {
      setAllNews(result.news || []);
    } else {
      console.error("Failed to fetch news:", result.error);
      // You might want to show an error message to the user here
    }
    setLoading(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleNewsClick = (id: string) => {
    navigate(`/news/${id}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getCurrentPageNews = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const currentPageNews = allNews.slice(startIndex, startIndex + pageSize);
    // 항상 4개의 아이템을 반환하기 위해 빈 아이템으로 채웁니다.
    // while (currentPageNews.length < 6) {
    //   currentPageNews.push({
    //     id: `empty-${currentPageNews.length}`,
    //     title: "",
    //     lastModifiedAt: "",
    //     mainImage: "",
    //   });
    // }
    return currentPageNews;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      />
    );
  }

  console.log(allNews);
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        padding: "24px",
        width: "100%",
        maxWidth: 1200,
        boxSizing: "border-box",
        margin: "0 auto",
      }}
    >
      <Title level={2}>대회소식</Title>
      <Row gutter={[16, 16]}>
        {getCurrentPageNews().map((item, index) => (
          <Col xs={12} sm={12} key={item.id}>
            {item.title ? (
              <Card
                hoverable
                cover={
                  <img
                    alt={item.title}
                    src={item.mainImage}
                    style={{ height: 200, objectFit: "cover", borderRadius: 8 }}
                  />
                }
                onClick={() => handleNewsClick(item.id)}
                style={{ border: "none", background: "transparent" }}
              >
                <Meta
                  title={item.title}
                  description={`${formatDate(item.lastModifiedAt)}`}
                />
              </Card>
            ) : (
              <div style={{ height: "100%", minHeight: 300 }} /> // 빈 카드 대신 사용할 placeholder
            )}
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        total={allNews.length}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </Space>
  );
}
