import React, { useState, useEffect, useMemo } from "react";
import {
  List,
  Space,
  Tag,
  Typography,
  Spin,
  Descriptions,
  Divider,
} from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { format, parse } from "date-fns";
import { ko } from "date-fns/locale";
import { getUserBranchLogs, getUserLogs } from "../../utils/log";
import { branchOptions } from "../../constants/branch";
import LogList from "../LogList";

const { Text, Title } = Typography;

const UserLogPopup = () => {
  const [userLogs, setUserLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");

  const [branchLogs, setBranchLogs] = useState<any[]>([]);

  const [resultLogs, setResultLogs] = useState<any[]>([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const nickname = searchParams.get("nickname");
    const username = searchParams.get("username");
    if (id) {
      setUserId(id);
      fetchUserLogs(id);
      fetchBranchLogs(id);
    }
    if (username) {
      setUsername(username);
    }
    if (nickname) {
      setNickname(nickname);
    }
  }, []);

  const fetchUserLogs = async (id: any) => {
    try {
      const logs = await getUserLogs(id);
      if (logs) {
        setUserLogs(logs);
      }
    } catch (error) {
      console.error("로그 조회에 실패했습니다:", error);
    }
    setLoading(false);
  };

  const fetchBranchLogs = async (id: any) => {
    try {
      const logs = await getUserBranchLogs(id);
      if (logs) {
        setBranchLogs(logs);
      }
    } catch (error) {
      console.error("로그 조회에 실패했습니다:", error);
    }
  };

  const getFieldColor = (field: any) => {
    if (!field) return;
    if (field.startsWith("point_")) return "blue";
    switch (field) {
      case "coin":
        return "gold";
      case "score":
        return "green";
      case "branch":
        return "purple";
      default:
        return "default";
    }
  };

  const getChangeColor = (changeAmount: any) => {
    return changeAmount > 0
      ? "#52c41a"
      : changeAmount < 0
      ? "#ff4d4f"
      : "#8c8c8c";
  };

  const formatTimestamp = (timestamp: any) => {
    try {
      const parsedDate = parse(
        timestamp,
        "yyyy-MM-dd HH:mm:ss.SSS 'KST'",
        new Date()
      );
      return format(parsedDate, "yyyy년 MM월 dd일 HH:mm:ss", { locale: ko });
    } catch (error) {
      console.error("Timestamp formatting error:", error);
      return timestamp;
    }
  };

  const parseTimestamp = (timestamp: any) => {
    const [datePart, timePart] = timestamp.split(" ");
    const [year, month, day] = datePart.split("-");
    const [hour, minute, second] = timePart.split(":");
    const [sec, millisec] = second.split(".");

    return new Date(year, month - 1, day, hour, minute, sec, millisec);
  };

  const sortedLogs = useMemo(() => {
    return [...branchLogs, ...userLogs]
      .map((log) => ({ ...log })) // 각 로그 객체의 얕은 복사본 생성
      .sort((a, b) => {
        const dateA = parseTimestamp(a.timestamp);
        const dateB = parseTimestamp(b.timestamp);
        return dateB.getTime() - dateA.getTime();
      });
  }, [branchLogs, userLogs]);

  useEffect(() => {
    console.log(sortedLogs);
    setResultLogs(sortedLogs);
  }, [sortedLogs]);

  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }

  return (
    <div style={{ padding: "20px" }}>
      <Title level={3}>사용자 로그</Title>
      <Descriptions
        bordered
        column={1}
        size="small"
        labelStyle={{ width: "30%" }}
      >
        <Descriptions.Item label="ID">{userId}</Descriptions.Item>
        <Descriptions.Item label="이름">{username}</Descriptions.Item>
        <Descriptions.Item label="닉네임">{nickname}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <LogList logs={resultLogs} />
    </div>
  );
};

export default UserLogPopup;
